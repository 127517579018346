let b = 1;
export default class Buffer {
    constructor(synth, props) {
        this.synth = synth;
        this.name = props.name || "buffer_" + b++;
        const gl = synth.renderer.gl;
        if (typeof props === "number")
            props = { data: props };
        // CASE 2: buffer from numbers -> by default create a Float32Array
        else if (Array.isArray(props))
            props = { data: new Float32Array(props) };
        // CASE 3: buffer from TypedArray
        else if (ArrayBuffer.isView(props))
            props = { data: props };
        //if (!props.buffer && !props.data) throw new Error("buffer needs data or buffer")
        this._usage = props.usage ?? GL_STATIC_DRAW;
        this._target = props.target ?? GL_ARRAY_BUFFER;
        this._divisor = props.divisor ?? 0;
        this._size = props.size ?? 1;
        this._buffer = props.buffer ?? gl.createBuffer();
        if (props.buffer || props.data) {
            this.data(props.buffer ? webglBufferToTypedArray(gl, props.buffer, props.type, props.target) : props.data, props.size, props.type);
        }
    }
    data(data, size = this._size, type = this._type) {
        const gl = this.synth.renderer.gl;
        type = bufferType(type);
        if (Array.isArray(data)) {
            if (!type) {
                console.warn("buffer.data: no type specified, assuming Float32Array");
                data = new Float32Array(data);
            }
            else {
                data = new (glTypeToTypedArray(type))(data);
            }
        }
        this._data = data;
        this._size = size;
        this._type = type;
        if (!this._type)
            this._type = WebGLBufferArrayConstructorType[this._data.constructor.name];
        gl.bindBuffer(this._target, this._buffer);
        gl.bufferData(this._target, this._data, this._usage);
        gl.bindBuffer(this._target, null);
        return this;
    }
    size(size) {
        this._size = size;
        return this;
    }
    usage(usage) {
        this._usage = typeof usage === "string" ? USAGES[usage] : usage;
        return this;
    }
    target(target) {
        this._target = target === "attr" ? GL_ARRAY_BUFFER : target === "indices" ? GL_ELEMENT_ARRAY_BUFFER : target;
        return this;
    }
    divisor(divisor) {
        this._divisor = divisor;
        return this;
    }
    type(type) {
        this._type = bufferType(type);
        return this;
    }
    destroy() {
        const gl = this.synth.renderer.gl;
        gl.deleteBuffer(this._buffer);
        this._data = null;
    }
    async print() {
        const data = await this.read();
        console.log(this.name, data);
    }
    async read(store = false) {
        const gl = this.synth.renderer.gl;
        return new Promise(resolve => {
            const sync = gl.fenceSync(gl.SYNC_GPU_COMMANDS_COMPLETE, 0);
            const checkStatus = () => {
                const status = gl.clientWaitSync(sync, gl.SYNC_FLUSH_COMMANDS_BIT, 0);
                if (status === gl.TIMEOUT_EXPIRED) {
                    //console.log("GPU is still busy")
                    setTimeout(checkStatus);
                }
                else if (status === gl.WAIT_FAILED) {
                    console.error("Something went wrong with the sync object");
                }
                else {
                    const result = webglBufferToTypedArray(gl, this._buffer, this._type, this._target);
                    if (store)
                        this._data = result;
                    resolve(result);
                }
            };
            checkStatus();
        });
        //return webglBufferToTypedArray(gl, b.buffer, b.type as WebGLBufferTypeArray, b.target)
    }
    clone() {
        return new Buffer(this.synth, {
            buffer: this._buffer,
            data: this._data,
            type: this._type,
            size: this._size,
            target: this._target,
            usage: this._usage,
            divisor: this._divisor,
        });
    }
}
// Constants
export const GL_ARRAY_BUFFER = 0x8892;
export const GL_ELEMENT_ARRAY_BUFFER = 0x8893;
export const GL_BYTE = 0x1400;
export const GL_UNSIGNED_BYTE = 0x1401;
export const GL_SHORT = 0x1402;
export const GL_UNSIGNED_SHORT = 0x1403;
export const GL_INT = 0x1404;
export const GL_UNSIGNED_INT = 0x1405;
export const GL_FLOAT = 0x1406;
export const GL_STATIC_DRAW = 0x88e4;
export const GL_DYNAMIC_DRAW = 0x88e8;
export const GL_STREAM_DRAW = 0x88e0;
export const GL_INTERLEAVED_ATTRIBS = 0x8c8c;
export const GL_SEPARATE_ATTRIBS = 0x8c8d;
// Mappers
const USAGES = {
    static: GL_STATIC_DRAW,
    dynamic: GL_DYNAMIC_DRAW,
    stream: GL_STREAM_DRAW,
};
const TYPES = {
    byte: GL_BYTE,
    ubyte: GL_UNSIGNED_BYTE,
    short: GL_SHORT,
    ushort: GL_UNSIGNED_SHORT,
    int: GL_INT,
    uint: GL_UNSIGNED_INT,
    float: GL_FLOAT,
};
export function bufferType(type) {
    if (typeof type === "string")
        return TYPES[type];
    return type;
}
const WebGLBufferTypeArrayConstructor = {
    [GL_BYTE]: Int8Array,
    [GL_UNSIGNED_BYTE]: Uint8Array,
    [GL_SHORT]: Int16Array,
    [GL_UNSIGNED_SHORT]: Uint16Array,
    [GL_INT]: Int32Array,
    [GL_UNSIGNED_INT]: Uint32Array,
    [GL_FLOAT]: Float32Array,
};
const WebGLBufferArrayConstructorType = {
    Int8Array: GL_BYTE,
    Uint8Array: GL_UNSIGNED_BYTE,
    Int16Array: GL_SHORT,
    Uint16Array: GL_UNSIGNED_SHORT,
    Int32Array: GL_INT,
    Uint32Array: GL_UNSIGNED_INT,
    Float32Array: GL_FLOAT,
};
// Utilties
// Given a GL type return the TypedArray needed
export function glTypeToTypedArray(type) {
    if (!(type in WebGLBufferTypeArrayConstructor))
        throw new Error(`glTypeToTypedArray no key: ${type}`);
    return WebGLBufferTypeArrayConstructor[type];
}
export function webglBufferToTypedArray(gl, buffer, type, target = gl.ARRAY_BUFFER) {
    const TypedArray = glTypeToTypedArray(type);
    gl.bindBuffer(target, buffer);
    const bufferSize = gl.getBufferParameter(target, gl.BUFFER_SIZE);
    const array = new TypedArray(bufferSize / TypedArray.BYTES_PER_ELEMENT);
    gl.getBufferSubData(target, 0, array);
    gl.bindBuffer(target, null);
    return array;
}
