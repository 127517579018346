import Buffer, { GL_SEPARATE_ATTRIBS } from "./buffer";
let tf = 1;
export default class TransformFeedback {
    constructor(synth, props = {}) {
        this.synth = synth;
        this.name = props.name || "transformFeedback_" + tf++;
        this.mode = props.mode || GL_SEPARATE_ATTRIBS;
        this._buffers = {};
        this.buffer = this.synth.renderer.gl.createTransformFeedback();
        this.buffers(props.buffers || {});
    }
    //////////////////////
    buffers(buffers) {
        const gl = this.synth.renderer.gl;
        for (const [name, attr] of Object.entries(buffers)) {
            if (this._buffers[name])
                gl.deleteBuffer(this._buffers[name]._buffer);
            this._buffers[name] = attr instanceof Buffer ? attr : new Buffer(this.synth, attr);
        }
        return this.bindBuffers();
    }
    keys() {
        return Object.keys(this._buffers);
    }
    bindBuffers() {
        const gl = this.synth.renderer.gl;
        gl.bindTransformFeedback(gl.TRANSFORM_FEEDBACK, this.buffer);
        let bufferBaseIndex = 0;
        for (const [_, b] of Object.entries(this._buffers)) {
            gl.bindBuffer(b._target, b._buffer);
            gl.bufferData(b._target, b._data, b._usage);
            gl.bindBufferBase(gl.TRANSFORM_FEEDBACK_BUFFER, bufferBaseIndex++, b._buffer);
        }
        gl.bindTransformFeedback(gl.TRANSFORM_FEEDBACK, null);
        gl.bindBuffer(gl.ARRAY_BUFFER, null);
        return this;
    }
}
