/**
 * Easing functions
 * @param {number} timeOrOffset current time
 * @param {number} start start value
 * @param {number} end end value
 * @param {number} duration duration
 * @returns {number}
 */
const Easings = {
    linear: time => time,
    quadraticIn: time => time ** 2,
    quadraticOut: time => time * (2 - time),
    quadraticInOut: time => (time < 0.5 ? 2 * time ** 2 : -1 + (4 - 2 * time) * time),
    cubicIn: time => time ** 3,
    cubicOut: time => --time * time * time + 1,
    cubicInOut: time => (time < 0.5 ? 4 * time ** 3 : (time - 1) * (2 * time - 2) * (2 * time - 2) + 1),
    quarticIn: time => time ** 4,
    quarticOut: time => 1 - (1 - time) ** 4,
    quarticInOut: time => (time < 0.5 ? 8 * time ** 4 : 1 - 8 * (1 - time) ** 4),
    quinticIn: time => time ** 5,
    quinticOut: time => 1 + (time - 1) ** 5,
    quinticInOut: time => (time < 0.5 ? 16 * time ** 5 : 1 + 16 * (time - 1) ** 5),
    sinusoidalIn: time => 1 - Math.cos((time * Math.PI) / 2),
    sinusoidalOut: time => Math.sin((time * Math.PI) / 2),
    sinusoidalInOut: time => 0.5 * (1 - Math.cos(Math.PI * time)),
    exponentialIn: time => 2 ** (10 * (time - 1)),
    exponentialOut: time => 1 - 2 ** (-10 * time),
    exponentialInOut: time => {
        time /= 0.5;
        if (time < 1) {
            return 0.5 * 2 ** (10 * (time - 1));
        }
        return 0.5 * (2 - 2 ** (-10 * --time));
    },
    circularIn: time => 1 - Math.sqrt(1 - time ** 2),
    circularOut: time => Math.sqrt(1 - (time - 1) ** 2),
    circularInOut: time => time < 0.5 ? 0.5 * (1 - Math.sqrt(1 - 4 * time ** 2)) : 0.5 * (Math.sqrt(1 - (2 * time - 2) ** 2) + 1),
    elasticIn: (time, amplitude = 1, period = 0.5) => {
        if (time === 0) {
            return 0;
        }
        if ((time /= 1) === 1) {
            return 1;
        }
        const s = (period / (2 * Math.PI)) * Math.asin(1 / amplitude);
        return -amplitude * 2 ** (10 * (time - 1)) * Math.sin(((time - s) * (2 * Math.PI)) / period);
    },
    elasticOut: (time, amplitude = 1, period = 0.5) => {
        if (time === 0) {
            return 0;
        }
        if ((time /= 1) === 1) {
            return 1;
        }
        const s = (period / (2 * Math.PI)) * Math.asin(1 / amplitude);
        return amplitude * 2 ** (-10 * time) * Math.sin(((time - s) * (2 * Math.PI)) / period) + 1;
    },
    elasticInOut: (time, amplitude = 1, period = 0.5) => {
        if (time === 0) {
            return 0;
        }
        if ((time /= 0.5) === 2) {
            return 1;
        }
        const s = (period / (2 * Math.PI)) * Math.asin(1 / amplitude);
        if (time < 1) {
            return -0.5 * (amplitude * 2 ** (10 * (time - 1)) * Math.sin(((time - s) * (2 * Math.PI)) / period));
        }
        return amplitude * 2 ** (-10 * (time - 1)) * Math.sin(((time - s) * (2 * Math.PI)) / period) * 0.5 + 1;
    },
    backIn: (time, overshoot = 1.70158) => {
        return time ** 2 * ((overshoot + 1) * time - overshoot);
    },
    backOut: (time, overshoot = 1.70158) => {
        return (time = time - 1) * time * ((overshoot + 1) * time + overshoot) + 1;
    },
    backInOut: (time, overshoot = 1.70158) => {
        if ((time /= 0.5) < 1) {
            return 0.5 * (time ** 2 * (((overshoot *= 1.525) + 1) * time - overshoot));
        }
        return 0.5 * ((time -= 2) * time * (((overshoot *= 1.525) + 1) * time + overshoot) + 2);
    },
    bounceIn: time => {
        return 1 - Easings.bounceOut(1 - time);
    },
    bounceOut: time => {
        if (time < 1 / 2.75) {
            return 7.5625 * time ** 2;
        }
        else if (time < 2 / 2.75) {
            return 7.5625 * (time -= 1.5 / 2.75) * time + 0.75;
        }
        else if (time < 2.5 / 2.75) {
            return 7.5625 * (time -= 2.25 / 2.75) * time + 0.9375;
        }
        return 7.5625 * (time -= 2.625 / 2.75) * time + 0.984375;
    },
    bounceInOut: time => {
        if (time < 0.5) {
            return Easings.bounceIn(time * 2) * 0.5;
        }
        return Easings.bounceOut(time * 2 - 1) * 0.5 + 0.5;
    },
};
export default Easings;
