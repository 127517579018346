import Meyda from "meyda";
const defaults = {
    cutoff: 2,
    smooth: 0.4,
    max: 15,
    scale: 10,
};
export default class Audio {
    constructor(synth, bins = 4) {
        this.numSlots = 4;
        this.prevBins = [];
        this.bins = [];
        this.fft = [];
        this.settings = [];
        this.showCanvas = false;
        this.isConnected = false;
        this.synth = synth;
        this.beat = {
            holdFrames: 20,
            threshold: 40,
            _cutoff: 0, // adaptive based on sound state
            decay: 0.98,
            _framesSinceBeat: 0, // keeps track of frames
        };
        this.setBins(bins);
        this.#createCanvas();
        this.synth.sandbox.export("a", this);
    }
    async start() {
        if (this.isConnected)
            return;
        try {
            const audioContext = new AudioContext();
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const microphone = audioContext.createMediaStreamSource(stream);
            this.meyda = Meyda.createMeydaAnalyzer({
                audioContext: audioContext,
                source: microphone,
                featureExtractors: [
                    "loudness",
                    //  'perceptualSpread',
                    //  'perceptualSharpness',
                    //  'spectralCentroid'
                ],
            });
            this.#updateAnalyzer();
            this.isConnected = true;
        }
        catch (error) {
            console.error("Errore nell'ottenere l'accesso al microfono:", error);
        }
    }
    #updateAnalyzer() {
        var features = this.meyda.get();
        if (features && features !== null) {
            this.vol = features.loudness.total;
            this.detectBeat(this.vol);
            const reducer = (accumulator, currentValue) => accumulator + currentValue;
            const spacing = Math.floor(features.loudness.specific.length / this.bins.length);
            this.prevBins = [...this.bins];
            this.bins = this.bins
                .map((_, index) => features.loudness.specific.slice(index * spacing, (index + 1) * spacing).reduce(reducer))
                .map((bin, index) => bin * (1.0 - this.settings[index].smooth) + this.prevBins[index] * this.settings[index].smooth);
            this.fft = this.bins.map((bin, index) => Math.max(0, (bin - this.settings[index].cutoff) / this.settings[index].scale));
            this.draw();
        }
        requestAnimationFrame(() => this.#updateAnalyzer());
    }
    setBins(numBins) {
        this.bins = Array(numBins).fill(0);
        this.prevBins = Array(numBins).fill(0);
        this.fft = Array(numBins).fill(0);
        this.settings = Array(numBins)
            .fill(0)
            .map((_, i) => ({
            cutoff: !this.settings[i] || typeof this.settings[i].cutoff === "undefined"
                ? defaults.cutoff
                : this.settings[i].cutoff,
            scale: !this.settings[i] || typeof this.settings[i].scale === "undefined" ? defaults.scale : this.settings[i].scale,
            smooth: !this.settings[i] || typeof this.settings[i].smooth === "undefined"
                ? defaults.smooth
                : this.settings[i].smooth,
        }));
    }
    setCutoff(cutoff) {
        if (typeof cutoff === "number")
            cutoff = Array(this.settings.length).fill(cutoff);
        this.settings = this.settings.map((el, index) => {
            el.cutoff = cutoff[index % this.settings.length];
            return el;
        });
    }
    setSmooth(smooth) {
        if (typeof smooth === "number")
            smooth = Array(this.settings.length).fill(smooth);
        this.settings = this.settings.map((el, index) => {
            el.smooth = smooth[index % this.settings.length];
            return el;
        });
    }
    setScale(scale) {
        if (typeof scale === "number")
            scale = Array(this.settings.length).fill(scale);
        this.settings = this.settings.map((el, index) => {
            el.scale = scale[index % this.settings.length];
            return el;
        });
    }
    #createCanvas() {
        this.canvas = document.createElement("canvas");
        this.canvas.width = 128;
        this.canvas.height = 60;
        this.canvas.style.position = "absolute";
        this.canvas.style.right = "0";
        this.canvas.style.bottom = "0";
        this.canvas.style.top = "auto";
        this.canvas.style.left = "auto";
    }
    draw() {
        if (!this.showCanvas || !this.canvas.parentElement)
            return;
        const canvasCtx = this.canvas.getContext("2d");
        canvasCtx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        canvasCtx.fillStyle = "rgb(200, 200, 200, 0.2)";
        canvasCtx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        var spacing = this.canvas.width / this.bins.length;
        var scale = 4;
        this.bins.forEach((bin, i) => {
            var height = bin * scale;
            canvasCtx.fillStyle = "red";
            canvasCtx.fillRect(i * spacing, this.canvas.height - height, spacing, height);
            var y = this.canvas.height - scale * this.settings[i].cutoff;
            canvasCtx.beginPath();
            canvasCtx.moveTo(i * spacing, y);
            canvasCtx.lineTo((i + 1) * spacing, y);
            canvasCtx.stroke();
            var yMax = this.canvas.height - scale * (this.settings[i].scale + this.settings[i].cutoff);
            canvasCtx.beginPath();
            canvasCtx.moveTo(i * spacing, yMax);
            canvasCtx.lineTo((i + 1) * spacing, yMax);
            canvasCtx.stroke();
        });
    }
    setBeat(options) {
        this.beat = {
            ...this.beat,
            ...options,
        };
    }
    detectBeat(level) {
        if (level > this.beat._cutoff && level > this.beat.threshold) {
            this.onBeat();
            this.beat._cutoff = level * 1.2;
            this.beat._framesSinceBeat = 0;
        }
        else {
            if (this.beat._framesSinceBeat <= this.beat.holdFrames) {
                this.beat._framesSinceBeat++;
            }
            else {
                this.beat._cutoff *= this.beat.decay;
                this.beat._cutoff = Math.max(this.beat._cutoff, this.beat.threshold);
            }
        }
    }
    onBeat() {
        const fn = this.synth.sandbox.varyng("onBeat");
        if (fn)
            fn();
    }
    hide() {
        this.showCanvas = false;
        this.canvas.remove();
    }
    show() {
        this.showCanvas = true;
        if (!this.canvas.parentElement)
            document.body.appendChild(this.canvas);
        this.draw();
    }
}
