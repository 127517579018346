import color from "./color";
import draw from "./draw";
import noise from "./noise";
import numbers from "./numbers";
import sd from "./signedDistance";
import texture from "./texture";
import transformations from "./transformations";
export const glsl_utilities = {
    ...noise,
    ...color,
    ...transformations,
    ...numbers,
    ...texture,
    ...draw,
    ...sd,
};
