import easings from "./easings";
import { map, mod } from "./utils";
Array.prototype.fast = function (speed = 1) {
    this._speed = speed;
    return this;
};
Array.prototype.smooth = function (smooth = 1) {
    this._smooth = smooth;
    return this;
};
Array.prototype.ease = function (ease = "linear") {
    if (typeof ease == "function") {
        this._smooth = 1;
        this._ease = ease;
    }
    else if (easings[ease]) {
        this._smooth = 1;
        this._ease = easings[ease];
    }
    else {
        console.warn(`Unknown easing function: ${ease}`);
    }
    return this;
};
Array.prototype.offset = function (offset = 0.5) {
    this._offset = offset % 1.0;
    return this;
};
// Array.prototype.bounce = function() {
//   this.modifiers.bounce = true
//   return this
// }
Array.prototype.fit = function (low = 0, high = 1) {
    let lowest = Math.min(...this);
    let highest = Math.max(...this);
    var newArr = this.map(num => map(num, lowest, highest, low, high));
    newArr._speed = this._speed;
    newArr._smooth = this._smooth;
    newArr._ease = this._ease;
    return newArr;
};
export function getArrayValue(arr = [], { time, bpm }) {
    if (typeof arr === "number")
        return arr;
    const speed = typeof arr._speed !== "undefined" ? arr._speed : 1;
    const smooth = typeof arr._smooth !== "undefined" ? arr._smooth : 0;
    const offset = typeof arr._offset !== "undefined" ? arr._offset : 0;
    const index = time * speed * (bpm / 60) + offset;
    if (smooth !== 0) {
        const ease = arr._ease ? arr._ease : easings["linear"];
        const _index = index - smooth / 2;
        const currValue = arr[Math.floor(mod(_index, arr.length))];
        const nextValue = arr[Math.floor(mod(_index + 1, arr.length))];
        const t = Math.min(mod(_index, 1) / smooth, 1);
        return ease(t) * (nextValue - currValue) + currValue;
    }
    else {
        return arr[Math.floor(mod(index, arr.length))];
    }
}
