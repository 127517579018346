export default {
    opRepeat: `
        vec3 opRepeat(vec3 pos, vec3 distance, vec3 limit) {
            return pos - distance * clamp(round(pos / distance), -limit, limit);
        }
        vec3 opRepeat(vec3 pos, float distance, float limit) {
            return opRepeat(pos, vec3(distance), vec3(limit));
        }
        vec3 opRepeat(vec3 pos, vec3 distance, float limit) {
            return opRepeat(pos, distance, vec3(limit));
        }
        vec3 opRepeat(vec3 pos, float distance, vec3 limit) {
            return opRepeat(pos, vec3(distance), limit);
        }
        vec3 opRepeat(vec3 pos) {
            return opRepeat(pos, 1., 1.);
        }
        vec3 opRepeat(vec3 pos, float distance) {
            return opRepeat(pos, distance, 1.);
        }
        vec3 opRepeat(vec3 pos, vec3 distance) {
            return opRepeat(pos, distance, 1.);
        }
    `,
};
