import { mat4, vec3 } from "gl-matrix";
export const DEFAULT_CAMERA = {
    fov: (45 * Math.PI) / 180,
    near: 0.1,
    far: 1000,
    position: [0, 0, 3],
    target: [0, 0, 0],
    up: [0, 1, 0],
};
export default class Camera {
    constructor(synth, type, p, controlTo) {
        this.synth = synth;
        const props = { ...DEFAULT_CAMERA, ...p };
        if (type === "perspective") {
            ;
            props.aspect = props.aspect ?? synth.width / synth.height;
        }
        this.viewMatrix = mat4.create();
        this.projectionMatrix = mat4.create();
        this.props = props;
        this._position = props.position || [0, 0, 6];
        this._target = props.target || [0, 0, 0];
        this._up = props.up || [0, 1, 0];
        switch (type) {
            case "perspective":
                this.perspective(props, controlTo);
                break;
            case "orthographic":
                this.orthographic(props);
                break;
        }
    }
    perspective(props, controlTo) {
        this.type = "perspective";
        const { fov, aspect, near, far } = props;
        mat4.perspective(this.projectionMatrix, fov, aspect, near, far);
        mat4.lookAt(this.viewMatrix, this._position, this._target, this._up);
        if (controlTo) {
            this.control = new Controls({
                element: controlTo,
                camera: this,
            });
        }
        return this;
    }
    orthographic(props) {
        this.type = "orthographic";
        const { left, right, top, bottom, near, far } = props;
        mat4.ortho(this.projectionMatrix, left, right, top, bottom, near, far);
        mat4.lookAt(this.viewMatrix, this._position, this._target, this._up);
        return this;
    }
    static isPerspective(camera) {
        return camera.type === "perspective";
    }
    updateMatrix() {
        if (Camera.isPerspective(this)) {
            const { fov, aspect, near, far } = this.props;
            mat4.perspective(this.projectionMatrix, fov, aspect, near, far);
            mat4.lookAt(this.viewMatrix, this._position, this._target, this._up);
        }
        else {
            const { left, right, top, bottom, near, far } = this.props;
            mat4.ortho(this.projectionMatrix, left, right, top, bottom, near, far);
            mat4.lookAt(this.viewMatrix, this._position, this._target, this._up);
        }
    }
    resize(width, height) {
        if (Camera.isPerspective(this)) {
            this.props.aspect = width / height;
        }
        else {
            const props = this.props;
            props.left = width / -2;
            props.right = width / 2;
            props.top = height / 2;
            props.bottom = height / -2;
            this.props = props;
        }
        this.updateMatrix();
    }
    position(position) {
        this._position[0] = position[0];
        this._position[1] = position[1];
        this._position[2] = position[2];
        return this;
    }
    target(target) {
        this._target[0] = target[0];
        this._target[1] = target[1];
        this._target[2] = target[2];
        return this;
    }
    move(position) {
        vec3.add(this._position, this._position, position);
        return this;
    }
    rotate(target) {
        vec3.add(this._target, this._target, target);
        return this;
    }
    update() { }
    zoom() {
        return this.control?.spherical.distance;
    }
}
const DEFAULT_CONTROLS = {
    minDistance: 0.01,
    maxDistance: 10000000000000000000000000,
    sensitivity: {
        zoom: 0.05,
        rotate: 0.01,
    },
};
class Controls {
    constructor(options) {
        if (options.sensitivity) {
            if (typeof options.sensitivity === "number") {
                options.sensitivity = {
                    zoom: options.sensitivity,
                    rotate: options.sensitivity,
                };
            }
        }
        options = { ...DEFAULT_CONTROLS, ...options };
        this.element = options.element;
        this.camera = options.camera;
        this.minDistance = options.minDistance;
        this.maxDistance = options.maxDistance;
        this.sensitivity = options.sensitivity;
        this.dragging = false;
        this.spherical = {
            distance: this.camera._position[2],
            polar: Math.PI / 2,
            azimuth: Math.PI / 2,
        };
        this.truckDistance = 0;
        this.pedestalDistance = 0;
        this.onDragStart = this.onDragStart.bind(this);
        this.onDragEnd = this.onDragEnd.bind(this);
        this.onMove = this.onMove.bind(this);
        this.onWheel = this.onWheel.bind(this);
        this.bindEvents();
        this.update();
    }
    bindEvents() {
        this.element.addEventListener("wheel", this.onWheel, { passive: false });
        this.element.addEventListener("pointerdown", this.onDragStart, { passive: false });
        this.element.addEventListener("pointermove", this.onMove, { passive: false });
        this.element.addEventListener("pointerup", this.onDragEnd, { passive: false });
    }
    ////////////////////
    update() {
        const { distance, polar, azimuth } = this.spherical;
        // Calcola le coordinate cartesiane dalla coordinate sferica
        const x = distance * Math.sin(polar) * Math.cos(azimuth);
        const y = distance * Math.cos(polar);
        const z = distance * Math.sin(polar) * Math.sin(azimuth);
        // Aggiorna la posizione della camera
        this.camera._position[0] = x;
        this.camera._position[1] = y;
        this.camera._position[2] = z;
        // Aggiorna il target della camera
        this.camera.updateMatrix();
    }
    onWheel(event) {
        this.adjustSphericalCoordinates(0, 0, -event.deltaY * (event.shiftKey ? 5 : 1));
        this.update();
    }
    onDragStart(event) {
        this.dragging = true;
    }
    onMove(event) {
        if (this.dragging) {
            const mx = event.movementX;
            const my = event.movementY;
            if (event.shiftKey) {
                /*
                if (this.camera.type === "orthographic") {
                    this.panLeft(mx, this.camera.viewMatrix)
                    this.panUp(-my, this.camera.viewMatrix)
                } else {
                    vec3.set(this.tmpVec3, this.camera._position[0], this.camera._position[1], this.camera._position[2])
                    vec3.sub(this.tmpVec3, this.tmpVec3, this.camera._target)

                    let targetDistance = vec3.length(this.tmpVec3)

                    targetDistance *= Math.tan((((this.camera.props as PerspectiveCameraProps).fov / 2) * Math.PI) / 180.0)

                    this.panLeft(mx * targetDistance, this.camera.viewMatrix)
                    this.panUp(-my * targetDistance, this.camera.viewMatrix)
                }
                */
                this.truckDistance += mx * this.sensitivity.rotate;
                this.pedestalDistance -= my * this.sensitivity.rotate;
            }
            else {
                this.adjustSphericalCoordinates(mx, -my, 0);
            }
            this.update();
        }
    }
    onDragEnd(event) {
        this.dragging = false;
    }
    ////////////////
    adjustSphericalCoordinates(deltaX, deltaY, deltaZoom) {
        const sensitivity = this.sensitivity;
        this.spherical.azimuth += deltaX * sensitivity.rotate;
        this.spherical.polar += deltaY * sensitivity.rotate;
        this.spherical.polar = Math.max(this.minDistance, Math.min(Math.PI - this.minDistance, this.spherical.polar));
        this.spherical.distance -= deltaZoom * sensitivity.zoom;
        this.spherical.distance = Math.max(this.minDistance, Math.min(this.maxDistance, this.spherical.distance));
    }
}
