import { GLSL_ARG } from "../typeMapping";
export default [
    {
        name: "mask",
        type: "combine",
        inputs: {},
        glsl: `
			vec4 _c0 = ${GLSL_ARG.color0};
			vec4 _c1 = ${GLSL_ARG.color1};
			
			float a = luminance(_c1.rgb);
			return vec4(_c0.rgb*a, a*_c0.a);
		`,
        require: ["luminance"],
    },
    {
        name: "add",
        type: "combine",
        inputs: {
            amount: 1,
        },
        glsl: `
			vec4 _c0 = ${GLSL_ARG.color0};
			vec4 _c1 = ${GLSL_ARG.color1};

			return (_c0 + _c1) * amount + _c0 * (1.0 - amount);
		`,
    },
    {
        name: "sub",
        type: "combine",
        inputs: {
            amount: 1,
        },
        glsl: `
			vec4 _c0 = ${GLSL_ARG.color0};
			vec4 _c1 = ${GLSL_ARG.color1};

			return (_c0 - _c1) * amount + _c0 * (1.0 - amount);
		`,
    },
    {
        name: "layer",
        type: "combine",
        inputs: [],
        glsl: `
			vec4 _c0 = ${GLSL_ARG.color0};
			vec4 _c1 = ${GLSL_ARG.color1};
		
			return vec4(mix(_c0.rgb, _c1.rgb, _c1.a), clamp(_c0.a + _c1.a, 0.0, 1.0));
		`,
    },
    {
        name: "blend",
        type: "combine",
        inputs: { amount: 0.5 },
        glsl: `
			return ${GLSL_ARG.color0} * (1.0 - amount) + ${GLSL_ARG.color1} * amount;
		`,
    },
    {
        name: "mult",
        type: "combine",
        inputs: {
            amount: 1,
        },
        glsl: `
			vec4 _c0 = ${GLSL_ARG.color0};
			vec4 _c1 = ${GLSL_ARG.color1};

			return _c0 * (1.0 - amount) + (_c0 * _c1) * amount;
		`,
    },
    {
        name: "diff",
        type: "combine",
        inputs: [],
        glsl: `
			vec4 _c0 = ${GLSL_ARG.color0};
			vec4 _c1 = ${GLSL_ARG.color1};

			return vec4(abs(_c0.rgb-_c1.rgb), max(_c0.a, _c1.a));
		`,
    },
];
