const DEFINES = {
    //PI: 3.1415926535897932384626433832795,
    PI: "radians(180.0)",
    HALF_PI: "radians(90.0)",
    TWO_PI: "radians(360.0)",
    // E: 2.718281828459045235360287471352,
    // PHI: 1.6180339887498948482045868343656,
    RIGHT: "vec3(1.0, 0.0, 0.0)",
    UP: "vec3(0.0, 1.0, 0.0)",
    FORWARD: "vec3(0.0, 0.0, 1.0)",
};
export default DEFINES;
