import { getArrayValue } from "./array";
import Texture from "./texture";
export default class Sequence {
    constructor(synth, index) {
        this.synth = synth;
        this.name = `seq${index}`;
        this.layers = [];
        this.currentIndex = 0;
        this.currentTime = 0;
        this.layerDuration = [];
        this.layerSpeed = [];
        this.running = false;
    }
    init(...layers) {
        this.layers = layers.map(l => (l instanceof Texture ? l.toGLSLSource() : l).generator());
        this.layerDuration = this.layers.map((_, i) => this.layerDuration[i] ?? 1);
        this.layerSpeed = this.layers.map((_, i) => this.layerSpeed[i] ?? 1);
        this.currentIndex = (this.currentIndex + this.layers.length) % this.layers.length;
        this.runnableIndex = this.layers.map((_, i) => i);
        return this;
    }
    out(o = this.synth.defaultOutput) {
        this.output = o;
        this.output.attachedSequence = this;
        return this.start();
    }
    duration(...d) {
        this.layerDuration = this.layers.map((_, i) => d[i % d.length] ?? 1);
        return this;
    }
    speed(...s) {
        this.layerSpeed = this.layers.map((_, i) => s[i % s.length] ?? 1);
        return this;
    }
    start(...layersIndex) {
        this.currentTime = 0;
        this.runnableIndex = layersIndex.length > 0 ? layersIndex : this.layers.map((_, i) => i);
        this.running = true;
        return this.next(this.currentIndex, this.currentIndex);
    }
    stop() {
        this.running = false;
        if (this.output)
            this.output.attachedSequence = null;
        return this;
    }
    update(dt) {
        if (!this.running || typeof this.output === "undefined")
            return;
        const ls = getArrayValue(this.layerSpeed[this.runnableIndex[this.currentIndex]] ?? 1, {
            time: this.synth.time,
            bpm: this.synth.bpm,
        });
        this.currentTime += dt * this.synth.speed * ls;
        const d = getArrayValue(this.layerDuration[this.runnableIndex[this.currentIndex]] ?? 1, {
            time: this.synth.time,
            bpm: this.synth.bpm,
        }) * ls;
        if (this.currentTime > d) {
            this.currentTime = 0;
            this.next(this.currentIndex, (this.currentIndex + 1) % this.runnableIndex.length);
        }
    }
    next(prevIndex, nextIndex) {
        const next = this.layers[this.runnableIndex[nextIndex]];
        if (next) {
            next.uniforms = next.uniforms.filter(({ name }) => name !== "time");
            next.uniforms.push({
                type: "float",
                name: "time",
                value: () => {
                    const s = getArrayValue(this.layerSpeed[this.runnableIndex[this.currentIndex]] ?? 1, {
                        time: this.synth.time,
                        bpm: this.synth.bpm,
                    });
                    return this.synth.time * s * this.synth.speed;
                },
            });
            this.output.generate(next);
            // when new shader is attached to output, it will be remove
            // the sequence if exists, so we need to reattach it
            this.output.attachedSequence = this;
            this.running = true;
            this.currentIndex = nextIndex;
        }
        return this;
    }
}
