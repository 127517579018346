import { GLSL_ARG } from "../../typeMapping";
import { KERNELS } from "../../utilities/texture";
export default [
    {
        name: "sobel",
        type: "src",
        inputs: [
            {
                type: "sampler2D",
                name: GLSL_ARG.tex,
                default: NaN,
            },
            {
                type: "float",
                name: "spread",
                default: 2,
            },
            {
                type: "float",
                name: "gain",
                default: 0.5,
            },
            {
                type: "float",
                name: "slopeness",
                default: 1,
            },
            {
                type: "float",
                name: "octaveFactor",
                default: 1,
            },
        ],
        glsl: `
			vec3 sob = vec3(0.0);
			vec2 derivative = vec2(0.0);
			
			float amplitude = gain;
			vec2 p = ${GLSL_ARG.st}.xy;
			for (int i = 0; i < 20; i++)
			{
				vec3 color = texture(${GLSL_ARG.tex}, p).rgb;
				vec2 grad = _sobel(${GLSL_ARG.tex}, p, spread);
				derivative += grad;
				sob += amplitude * color / (1.0 + mix(0.0, dot(derivative, derivative), slopeness));
				amplitude = pow(amplitude * gain, octaveFactor);
			}

			return vec4(sob, texture(${GLSL_ARG.tex}, ${GLSL_ARG.st}.xy).a);
        `,
        require: ["luminance", "sobel"],
    },
    {
        name: "convolution",
        type: "src",
        inputs: [
            {
                type: "sampler2D",
                name: GLSL_ARG.tex,
                default: NaN,
            },
            {
                type: "float[9]",
                name: "kernel",
                default: KERNELS.NORMAL,
            },
            {
                type: "float",
                name: "spread",
                default: 1,
            },
        ],
        glsl: `
			vec2 _spread = (vec2(1.0, 1.0) / resolution) * spread;
			return _convolution(${GLSL_ARG.tex}, ${GLSL_ARG.st}.xy, kernel, _spread);
		`,
        require: ["convolution"],
        transform: (input, kernel = "NORMAL", spread = 1) => {
            if (typeof kernel === "string") {
                kernel = kernel.toUpperCase();
                if (!(kernel in KERNELS)) {
                    console.warn(`kernel ${kernel} not found, using NORMAL instead`);
                    kernel = "NORMAL";
                }
                kernel = KERNELS[kernel];
            }
            if (Array.isArray(kernel) && kernel.length !== 9)
                kernel = new Array(9).fill(0).map((_, i) => kernel[i % kernel.length] || 0);
            return [input, kernel, spread];
        },
        help: `
			usage: convolution(<out or source>, <kernel_name> , <spread>)
			default: convolution(NaN, 'normal', 1)
			example: convolution(o0, 'normal', 1)

			kernels: ${Object.keys(KERNELS)
            .map(k => k.toLowerCase())
            .join(", ")} 
		`,
    },
    {
        name: "ascii",
        type: "src",
        inputs: [
            { name: "source", default: NaN, type: "sampler2D" },
            { name: "asciiTexture", default: NaN, type: "sampler2D" },
            { name: "charLen", default: [1, 1], type: "vec2", description: "[row, cols] of asciiTexture" },
            { name: "pixelateX", default: 20, type: "float" },
            { name: "pixelateY", default: 20, type: "float" },
            { name: "multiplier", default: 1, type: "float" },
        ],
        glsl: `
			vec2 _st = ${GLSL_ARG.st}.xy;
			float rows = charLen.x;
			float cols = charLen.y;
			float chars = (rows * cols);
			vec2 pixelate = vec2(pixelateX, pixelateY);
            vec2 xy = (floor(_st * pixelate) + 0.5) / pixelate;
            vec4 color = texture(source, xy);
		
           	float luminosity = pow(luminance(color.rgb), multiplier) * chars;
			float luminosityCols = floor(mod(luminosity, cols));
			float luminosityRows = floor(luminosity / cols);

            float asciiCharOffsetX = luminosityCols / cols;
			float asciiCharOffsetY = luminosityRows / rows;
            
			vec2 position = vec2(
                fract(_st.x * pixelate.x) * (1. / cols) + asciiCharOffsetX,
				fract(_st.y  * pixelate.y) * (1. / rows) + asciiCharOffsetY
            );

            vec3 charColor = texture(asciiTexture, position).xyz;
			
            return vec4(charColor, color.a);
		`,
        require: ["luminance"],
        transform: (source, asciiTexture, charLen, pixelateX, pixelateY, multiplier) => {
            if (typeof charLen === "number")
                charLen = [1, charLen];
            return [source, asciiTexture, charLen, pixelateX, pixelateY, multiplier];
        },
    },
    {
        name: "bloom",
        type: "src",
        inputs: {
            source: NaN,
            intensity: 2,
            spread: 1,
            threshold: 0.5,
        },
        glsl: `
			vec2 _st = ${GLSL_ARG.st}.xy;
			vec4 sum = vec4(0.0);

			float uv_x = _st.x;
			float uv_y = _st.y;

			float spread_x = spread / resolution.x;
			float spread_y = spread / resolution.y;

			for (int n = 0; n < 9; ++n) {
				uv_y = _st.y + (spread_y * float(n - 4));
				vec4 h_sum = vec4(0.0);
				h_sum += texture(source, vec2(uv_x - (4.0 * spread_x), uv_y));
				h_sum += texture(source, vec2(uv_x - (3.0 * spread_x), uv_y));
				h_sum += texture(source, vec2(uv_x - (2.0 * spread_x), uv_y));
				h_sum += texture(source, vec2(uv_x - spread_x, uv_y));
				h_sum += texture(source, vec2(uv_x, uv_y));
				h_sum += texture(source, vec2(uv_x + spread_x, uv_y));
				h_sum += texture(source, vec2(uv_x + (2.0 * spread_x), uv_y));
				h_sum += texture(source, vec2(uv_x + (3.0 * spread_x), uv_y));
				h_sum += texture(source, vec2(uv_x + (4.0 * spread_x), uv_y));
				sum += h_sum / 9.0;
			}
			
			vec4 color = texture(source, _st);

			if (luminance(color) < threshold) {
				return color + ((sum / 9.0) * intensity);
			}

			return color;
		`,
        require: ["luminance"],
    },
    {
        name: "grid",
        type: "src",
        inputs: {
            scale: [10, 10],
            threshold: [0.1, 0.1],
        },
        glsl: `
			vec2 _st = ${GLSL_ARG.st}.xy + vec2(threshold / scale / 2.);
			vec2 _grid = fract(_st * scale);
			vec2 _lines = step(threshold, _grid);
			float _line = min(_lines.x, _lines.y);
			return vec4(vec3(_line), 1.0);

		`,
        transform: (scale, threshold) => {
            if (typeof scale === "number")
                scale = [scale, scale];
            if (typeof threshold === "number")
                threshold = [threshold, threshold];
            return [scale, threshold];
        },
    },
];
