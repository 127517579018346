import { strToLines } from "./utils";
export default class Sandbox {
    constructor(global = self) {
        this.onUnload = null;
        this.global = global;
    }
    export(method, value) {
        this.global[method] = value;
    }
    remove(method) {
        delete this.global[method];
    }
    varyng(property, value) {
        return this.global[property] || (this.global[property] = value);
    }
    async eval(code) {
        try {
            const generated = this.generateCode(code);
            this.onUnload && this.onUnload();
            const fn = new Function(generated);
            const result = await fn.call(this.global);
            if (result instanceof Error) {
                console.log(strToLines(fn.toString()));
                return result;
            }
            this.onUnload = typeof result === "function" ? result : null;
            return null;
        }
        catch (e) {
            return e;
        }
    }
    generateCode(code) {
        return `
			try{ 
				with(this) {
					async function code() {
						${code} 
						return null
					}; 
					return code();
				}
			} catch(e) { return e }
		`;
    }
}
