export default {
    sdBox: `
        float sdBox(vec3 p, vec3 size) {
            vec3 q = abs(p) - size;
            return length(max(q,0.0)) + min(max(q.x,max(q.y,q.z)),0.0);
        }
        float sdBox(vec3 p) { return sdBox(p, vec3(0.5)); }
        float sdBox(vec3 p, float s) { return sdBox(p, vec3(s)); }
    `,
    sdSphere: `
        float sdSphere(vec3 p, float radius) {
            return length(p) - radius;
        }
        float sdSphere(vec3 p) { return sdSphere(p, .5); }
    `,
};
