// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics"
import { initializeApp } from "firebase/app"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyCLX-0d5Dh6AE6Ld-orCG9DVI8u2JRp9Lw",
	authDomain: "neurocinema-54d79.firebaseapp.com",
	projectId: "neurocinema-54d79",
	storageBucket: "neurocinema-54d79.appspot.com",
	messagingSenderId: "669006873703",
	appId: "1:669006873703:web:982eafec5be35d210940c2",
	measurementId: "G-PEFLQ7ZDMB",
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
