import React from "react"

interface AnimProps {
	showAfter?: number
	hideAfter?: number
	children: React.ReactNode | ((started: boolean) => React.ReactNode)
	persistentChild?: boolean
}

function Anim(props: AnimProps) {
	const ref = React.useRef<HTMLDivElement>(null)
	const [disableRender, setDisableRender] = React.useState(props.showAfter && !props.hideAfter)
	const [toShow, setToShow] = React.useState(!props.showAfter)
	const [toHide, setToHide] = React.useState(false)

	React.useEffect(() => {
		if (!ref.current) return

		function show() {
			setDisableRender(false)
			setToHide(false)
			setToShow(true)
		}
		function hide() {
			setToShow(false)
			setToHide(true)
			ref.current?.addEventListener("animationend", () => {
				setDisableRender(true)
			})
		}

		if (props.showAfter && props.showAfter > 0) {
			setTimeout(show, props.showAfter)
		} else {
			show()
		}

		if (props.hideAfter) {
			setTimeout(hide, props.hideAfter)
		}
	}, [ref])

	return (
		<div ref={ref} className={`anim ${toShow ? "anim--to-show" : ""} ${toHide ? "anim--to-hide" : ""}`}>
			{!props.persistentChild && (disableRender || (!toShow && !toHide))
				? null
				: typeof props.children === "function"
				? props.children(toShow)
				: props.children}
		</div>
	)
}

export default Anim
