import { GLSLSource } from "../glsl";
import Generate from "../shader/generator";
export default class Material extends GLSLSource {
    constructor(synth, glslFunction, args, parent) {
        super(synth, glslFunction, args, parent);
        this.materialUniforms = {};
        this.materialDefines = {};
        this.materialVaryings = {};
    }
    generator() {
        const generator = super.generator();
        Generate.merge(generator, {
            uniforms: Object.keys(this.materialUniforms).map(key => ({
                name: key,
                type: this.materialUniforms[key][0],
                value: this.materialUniforms[key][1],
            })),
            defines: Object.keys(this.materialDefines).map(key => `#define ${key} ${this.materialDefines[key]}`),
        });
        return generator;
    }
}
