import { GLSLSource } from "../glsl";
import { GLSL_ARG, SIZE_TO_TYPE } from "../glsl/typeMapping";
import Output from "../output";
import Renderer from "../renderer";
import Texture from "../texture";
import { strToLines, ucfirst } from "../utils";
import Generate from "./generator";
import TransformFeedback from "./transformFeedback";
import VAO from "./vao";
export const GL_POINTS = 0x0000;
export const GL_TRIANGLES = 0x0004;
export const GL_TRIANGLE_FAN = 0x0006;
export const GL_FRAGMENT_SHADER = 0x8b30;
export const GL_VERTEX_SHADER = 0x8b31;
export var ShaderVaryingsMode;
(function (ShaderVaryingsMode) {
    ShaderVaryingsMode[ShaderVaryingsMode["IN"] = 1] = "IN";
    ShaderVaryingsMode[ShaderVaryingsMode["OUT"] = 2] = "OUT";
    ShaderVaryingsMode[ShaderVaryingsMode["SHARED"] = 3] = "SHARED";
})(ShaderVaryingsMode || (ShaderVaryingsMode = {}));
export default class Shader {
    constructor(synth, props = {}) {
        this.program = null;
        this.uniformsLocation = {};
        props = { ...Shader.defaultGydraShader, ...props };
        this.name = props.name;
        this.synth = synth;
        this.gl = synth.renderer.gl;
        this.drawCalls = 0;
        this.needsUpdate = false;
        this.precision = props.precision || synth.precision;
        this.width = props.width ?? synth.width;
        this.height = props.height ?? synth.height;
        this.defines = { ...(props.defines || {}) };
        this._varyings = { ...(props.varyings || {}) };
        this.shaderUniforms = {};
        this.uniforms = Generate.sanitizeUniforms(props.uniforms || []);
        this._autoClear = props.autoClear !== false;
        if (props.vao)
            this.VAO = props.vao instanceof VAO ? props.vao : new VAO(this.synth, props.vao);
        if (props.transformFeedback) {
            this._transformFeedback =
                props.transformFeedback instanceof TransformFeedback
                    ? props.transformFeedback
                    : new TransformFeedback(this.synth, props.transformFeedback);
        }
        if (typeof props.fragment !== "undefined")
            this.fragment(props.fragment);
        if (props.vertex)
            this.vertex(props.vertex);
        synth.shaders.push(this);
    }
    ////////////////////
    compile() {
        if (this.VAO) {
            for (const [name, sb] of Object.entries(this.VAO.attributes)) {
                this.defines["HAS_" + name.toUpperCase()] = true;
                this._varyings[name] = {
                    type: SIZE_TO_TYPE[sb._size],
                    mode: ShaderVaryingsMode.IN,
                    shader: GL_VERTEX_SHADER,
                };
                this._varyings["v" + ucfirst(name.toLowerCase())] = {
                    type: SIZE_TO_TYPE[sb._size],
                    mode: ShaderVaryingsMode.SHARED,
                };
            }
            if (this.VAO._instances) {
                this.defines["INSTANCES"] = this.VAO._instances;
            }
            else {
                delete this.defines["INSTANCES"];
            }
        }
        // after defines and varyings are set we can generate shaders
        this.#generateVertex();
        this.#generateFragment();
        this.#createProgram();
    }
    vao(vao) {
        if (vao) {
            this.VAO = vao instanceof VAO ? vao : new VAO(this.synth, vao);
            if (this.program)
                this.VAO.bindVertexAttrs(this);
        }
        else
            this.VAO = null;
        return this;
    }
    attrs(attrs) {
        if (this.VAO)
            this.VAO.attrs(attrs);
        return this;
    }
    transformFeedback(tf) {
        this._transformFeedback = tf instanceof TransformFeedback ? tf : new TransformFeedback(this.synth, tf);
        return this;
    }
    ////////////////////
    #createProgram() {
        if (this.program) {
            this.gl.deleteProgram(this.program);
        }
        this.program = Renderer.createProgram(this.gl, this.vertexShader, this.fragmentShader, this._transformFeedback?.keys(), this._transformFeedback?.mode);
        if (!this.program) {
            throw new Error("Failed to create program");
        }
        if (this.VAO) {
            this.VAO.bindVertexAttrs(this);
        }
        this.uniformsLocation = {};
        return this;
    }
    fragment(fragment) {
        this.fragmentSource =
            typeof fragment === "boolean"
                ? Generate.sanitizeShaderString("", this.precision) // rasterizerDiscard`#version 300 es\nprecision highp float;\nvoid main() {}`
                : typeof fragment === "string"
                    ? Generate.sanitizeShaderString(fragment, this.precision)
                    : fragment instanceof GLSLSource
                        ? fragment.generator()
                        : fragment;
        if (fragment instanceof GLSLSource) {
            this._varyings = { ...this._varyings, ...Shader.defaultGydraShader.varyings };
        }
        return this;
    }
    vertex(vertex) {
        this.vertexSource =
            typeof vertex === "string"
                ? Generate.sanitizeShaderString(vertex, this.precision)
                : vertex instanceof GLSLSource
                    ? vertex.generator()
                    : vertex;
        return this;
    }
    #generateFragment() {
        const generated = typeof this.fragmentSource !== "string"
            ? Generate.fragment(this.precision, this.fragmentSource, this.uniforms, this.defines, this._varyings)
            : this.fragmentSource;
        const gl = this.gl;
        if (this.fragmentShader && this.program) {
            gl.detachShader(this.program, this.fragmentShader);
            gl.deleteShader(this.fragmentShader);
        }
        const [fragmentShader, fragmentShaderError] = Renderer.createShader(gl, gl.FRAGMENT_SHADER, generated);
        if (fragmentShaderError) {
            console.log(strToLines(generated));
            throw new Error(fragmentShaderError);
        }
        this.fragmentGenerated = generated;
        this.fragmentShader = fragmentShader;
        // if (this.program) {
        // 	gl.attachShader(this.program, this.fragmentShader)
        // 	gl.linkProgram(this.program)
        // 	if (!gl.getProgramParameter(this.program, gl.LINK_STATUS)) {
        // 		throw new Error("Failed to link program: " + gl.getProgramInfoLog(this.program))
        // 	}
        // }
        return this;
    }
    #generateVertex() {
        const generated = typeof this.vertexSource !== "string"
            ? Generate.vertex(this.precision, this.vertexSource, this.uniforms, this.defines, this._varyings)
            : this.vertexSource;
        if (this.vertexGenerated === generated)
            return this;
        const gl = this.gl;
        if (this.vertexShader && this.program) {
            gl.detachShader(this.program, this.vertexShader);
            gl.deleteShader(this.vertexShader);
        }
        const [vertexShader, vertexShaderError] = Renderer.createShader(gl, gl.VERTEX_SHADER, generated);
        if (vertexShaderError) {
            console.log(strToLines(generated));
            throw new Error(vertexShaderError);
        }
        this.vertexGenerated = generated;
        this.vertexShader = vertexShader;
        // if (this.program) {
        // 	gl.attachShader(this.program, this.vertexShader)
        // 	gl.linkProgram(this.program)
        // 	if (!gl.getProgramParameter(this.program, gl.LINK_STATUS)) {
        // 		throw new Error("Failed to link program: " + gl.getProgramInfoLog(this.program))
        // 	}
        // }
        return this;
    }
    /////////////////////////
    define(toAdd, value) {
        if (typeof toAdd === "string") {
            toAdd = { [toAdd]: value };
        }
        for (const [name, value] of Object.entries(toAdd)) {
            if (value === null) {
                delete this.defines[name];
                continue;
            }
            this.defines[name] = value;
        }
        return this;
    }
    varyngs(v) {
        this._varyings = { ...this._varyings, ...v };
        return this;
    }
    uniform(toAdd, value) {
        toAdd = typeof toAdd === "string" ? { [toAdd]: value } : toAdd;
        const toAddSanitized = Generate.sanitizeUniforms(toAdd);
        for (const u of toAddSanitized) {
            const index = this.uniforms.findIndex(uniform => uniform.name === u.name);
            if (index >= 0) {
                if (u.value === null) {
                    this.uniforms.splice(index, 1);
                    continue;
                }
                this.uniforms[index].value = u.value;
            }
            else if (u !== null) {
                this.uniforms.push(u);
            }
        }
        return this;
    }
    ////////////////////
    resize(width, height) {
        this.width = width;
        this.height = height;
        if (this.shaderUniforms["resolution"])
            this.shaderUniforms["resolution"] = ["vec2", [width, height]];
        if (this.texture && !(this.texture instanceof Output)) {
            this.texture.resize(width, height);
        }
        return this;
    }
    update(uniforms = []) {
        if (this.needsUpdate === false)
            return this;
        uniforms = [...uniforms, ...this.uniforms];
        // this is why generator has uniforms with value as function
        if (typeof this.fragmentSource !== "string" && this.fragmentSource && this.fragmentSource.uniforms)
            uniforms = [...uniforms, ...this.fragmentSource.uniforms];
        if (typeof this.vertexSource !== "string" && this.vertexSource && this.vertexSource.uniforms)
            uniforms = [...uniforms, ...this.vertexSource.uniforms];
        this.shaderUniforms = { ...this.shaderUniforms, ...Generate.uniforms(this.synth, uniforms) };
        if (this.texture && this.uniformsLocation["prevFrame"]) {
            this.shaderUniforms["prevFrame"] = ["sampler2D", this.texture.texture()];
        }
        return this.draw(this.texture);
    }
    forceUpdate() {
        const needsUpdate = this.needsUpdate;
        this.needsUpdate = true;
        this.update();
        this.needsUpdate = needsUpdate;
        return this;
    }
    autoClear(autoClear = true) {
        this._autoClear = autoClear;
        return this;
    }
    draw(to = this.texture) {
        if (!this.VAO || !this.program) {
            console.warn(`[${this.name}] ${!this.VAO ? "VAO" : "Program"} not defined`);
            return this;
        }
        const gl = this.gl;
        gl.useProgram(this.program);
        const textureUnit = this.bindUniforms(0);
        if (to && (!this.VAO || !this.VAO._rasterizerDiscard)) {
            to.next();
            to.updates++;
            gl.bindFramebuffer(gl.FRAMEBUFFER, to.framebuffer());
        }
        if (this.VAO) {
            // draw
            this.VAO._rasterizerDiscard && gl.enable(gl.RASTERIZER_DISCARD);
            gl.viewport(0, 0, this.width, this.height);
            if (this._autoClear) {
                gl.clearColor(0.0, 0.0, 0.0, 0.0);
                gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
            }
            gl.bindVertexArray(this.VAO.buffer);
            if (this._transformFeedback) {
                gl.bindTransformFeedback(gl.TRANSFORM_FEEDBACK, this._transformFeedback.buffer);
                gl.beginTransformFeedback(this.VAO._primitive);
            }
            else if (this.VAO.indices)
                gl.bindBuffer(this.VAO.indices._target, this.VAO.indices._buffer);
            if (this.VAO._elements > 0) {
                if (this.VAO._instances > 0)
                    gl.drawElementsInstanced(this.VAO._primitive, this.VAO._elements, this.VAO.indices._type, 0, this.VAO._instances);
                else
                    gl.drawElements(this.VAO._primitive, this.VAO._elements, this.VAO.indices._type, 0);
            }
            else
                gl.drawArrays(this.VAO._primitive, 0, this.VAO._count);
            // clear
            if (this._transformFeedback) {
                gl.endTransformFeedback();
                gl.bindTransformFeedback(gl.TRANSFORM_FEEDBACK, null);
            }
            gl.bindBuffer(gl.ARRAY_BUFFER, null);
            gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, null);
            gl.bindVertexArray(null);
            this.VAO._rasterizerDiscard && gl.disable(gl.RASTERIZER_DISCARD);
        }
        for (let u = 0; u < textureUnit; u++) {
            gl.activeTexture(gl.TEXTURE0 + u);
            gl.bindTexture(gl.TEXTURE_2D, null);
        }
        if (to && (!this.VAO || !this.VAO._rasterizerDiscard)) {
            gl.bindFramebuffer(gl.FRAMEBUFFER, null);
        }
        this.drawCalls++;
        //gl.flush()
        return this;
    }
    out(texture) {
        this.compile();
        if (texture) {
            // TODO: move to output
            texture instanceof Output && texture.attachedSequence && texture.attachedSequence.stop();
            this.texture = texture;
            texture.attachedShader = this;
        }
        else {
            if (this.texture)
                this.texture.attachedShader = null;
            this.texture = null;
        }
        return this.forceUpdate();
    }
    /////////////////////////
    clear() {
        this.uniforms = [];
        this.shaderUniforms = {};
        this.defines = {};
        this._varyings = {};
        this.vertexSource = null;
        this.fragmentSource = null;
        this.vertexGenerated = null;
        this.fragmentGenerated = null;
        this.activate(false);
    }
    // activete update recursively
    activate(active = true, force = false /* user by synth drawers */) {
        if (this.needsUpdate === active && force === false)
            return this;
        this.needsUpdate = active;
        function activateUniforms(uniforms) {
            for (const uniform of uniforms) {
                if (uniform.value instanceof Shader || uniform.value instanceof Output) {
                    uniform.value.activate(active);
                }
                else if (uniform.value instanceof Texture) {
                    uniform.value.needsUpdate = active;
                    if (uniform.value.attachedShader)
                        uniform.value.attachedShader.needsUpdate = active;
                }
            }
        }
        if (typeof this.fragmentSource !== "string" && this.fragmentSource && this.fragmentSource.uniforms)
            activateUniforms(this.fragmentSource.uniforms);
        if (typeof this.vertexSource !== "string" && this.vertexSource && this.vertexSource.uniforms)
            activateUniforms(this.vertexSource.uniforms);
        if (this.uniforms)
            activateUniforms(this.uniforms);
    }
    ////////////////////
    bindUniforms(initialTextureUnit = 0) {
        const gl = this.gl;
        let textureUnit = initialTextureUnit;
        for (let i = 0, uniformKeys = Object.keys(this.shaderUniforms), len = uniformKeys.length; i < len; i++) {
            const uniformKey = uniformKeys[i];
            if (!this.uniformsLocation[uniformKey]) {
                this.uniformsLocation[uniformKey] = gl.getUniformLocation(this.program, uniformKey);
            }
            if (this.uniformsLocation[uniformKey] !== null) {
                this.synth.renderer.bindUniform(gl, uniformKey, this.shaderUniforms[uniformKey][0], this.uniformsLocation[uniformKey], this.shaderUniforms[uniformKey][1], textureUnit);
                if (this.shaderUniforms[uniformKey][1] instanceof WebGLTexture) {
                    textureUnit++;
                }
            }
            /*else {
                console.warn("Uniform not found", uniformKey)
            }*/
        }
        return textureUnit;
    }
    // #clearUnusedUniforms() {
    // 	const gl = this.gl
    // 	for (let i = 0, len = this.uniforms.length; i < len; i++) {
    // 		const uniformKey = this.uniforms[i].name
    // 		if (uniformKey === "prevFrame") continue // prevFrame is always used
    // 		if (gl.getUniformLocation(this.program, uniformKey) === null) {
    // 			console.warn("Uniform not found", uniformKey)
    // 			if (this.vertexGenerator && this.vertexGenerator.uniforms) {
    // 				this.vertexGenerator.uniforms = this.vertexGenerator.uniforms.filter(uniform => uniform.name !== uniformKey)
    // 				this.updateGenerators()
    // 			}
    // 			if (this.fragmentGenerator && this.fragmentGenerator.uniforms) {
    // 				this.fragmentGenerator.uniforms = this.fragmentGenerator.uniforms.filter(
    // 					uniform => uniform.name !== uniformKey
    // 				)
    // 				this.updateGenerators()
    // 			}
    // 			delete this.uniforms[uniformKey]
    // 			delete this.uniformsLocation[uniformKey]
    // 		}
    // 	}
    // }
    //////////////////
    // created by renderer
    static { this.defaultGydraShader = {
        vertex: { value: () => `vec4(${GLSL_ARG.st}, 1.0)` },
        varyings: {
            fragColor: {
                type: "vec4",
                mode: ShaderVaryingsMode.OUT,
                shader: GL_FRAGMENT_SHADER,
            },
        },
        uniforms: {},
        defines: {},
    }; }
    static { this.defaultGydraVAO = null; }
}
