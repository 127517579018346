import { setFunction } from "../../glsl";
import { GLSL_ARG } from "../../glsl/typeMapping";
import Generate from "../../shader/generator";
import Material from "../material";
const pbr = {
    name: "pbr",
    type: "src",
    glsl: `
		vec2 uv = ${GLSL_ARG.st}.xy;

	#ifdef HAS_TEXCOORD
		uv = vTexcoord;
	#endif

		vec4 color = vec4(1.0);
	#ifdef HAS_BASECOLOR
		color = baseColor;
	#endif
	#ifdef HAS_VERTEXCOLOR
		color = vVertexcolor;
	#endif
	#ifdef HAS_BASECOLORTEXTURE
		color = texture(baseColorTexture, vec2(uv.x, 1. - uv.y));
	#ifdef HAS_BASECOLORFACTOR
		color *= baseColorFactor;
	#endif
	#endif

	#ifdef HAS_NORMAL
		vec3 normal = normalize(vNormal);
	#else
		vec3 normal = vec3(0.0, 0.0, 1.0);
	#endif

		vec3 lightDirection = normalize(vec3(0.5, 0.5, 1.0));
		float light = dot(normal, lightDirection);

		return vec4(color.rgb * light, color.a);
	`,
};
setFunction(pbr);
export default class PBR extends Material {
    constructor(synth, props = {}) {
        super(synth, pbr);
        props.baseColor = props.baseColor || [1, 1, 1, 1];
        Object.keys(props).forEach(key => {
            const value = props[key];
            if (value) {
                const type = Generate.toUniformType(value);
                if (type !== -1) {
                    this.materialUniforms[key] = [type, value];
                    this.materialDefines["HAS_" + key.toUpperCase()] = 1;
                }
            }
        });
    }
}
