import { GLSL_ARG } from "../typeMapping";
export default [
    {
        name: "modulateRepeat",
        type: "combineCoord",
        inputs: {
            repeatX: 3,
            repeatY: 3,
            offsetX: 0.5,
            offsetY: 0.5,
        },
        glsl: `   
			vec2 _st = ${GLSL_ARG.st}.xy * vec2(repeatX, repeatY);
			_st.x += step(1., mod(_st.y,2.0)) + ${GLSL_ARG.color0}.r * offsetX;
			_st.y += step(1., mod(_st.x,2.0)) + ${GLSL_ARG.color0}.g * offsetY;
			${GLSL_ARG.st}.xy = fract(_st);
			return vec3(_st, ${GLSL_ARG.st}.z);
		`,
    },
    {
        name: "modulateRepeatX",
        type: "combineCoord",
        inputs: { reps: 3, offset: 0.5 },
        glsl: `   
			vec2 _st = ${GLSL_ARG.st}.xy * vec2(reps, 1.0);
		 	//  float f =  mod(_st.y, 2.0);
		 	_st.y += step(1., mod(_st.x, 2.0)) + ${GLSL_ARG.color0}.r * offset;
			return vec3(fract(_st), ${GLSL_ARG.st}.z);
		`,
    },
    {
        name: "modulateRepeatY",
        type: "combineCoord",
        inputs: {
            reps: 3,
            offset: 0.5,
        },
        glsl: `   
			vec2 _st = ${GLSL_ARG.st}.xy * vec2(reps, 1.0);
			//  float f =  mod(_st.y,2.0);
			_st.x += step(1., mod(_st.y, 2.0)) + ${GLSL_ARG.color0}.r * offset;
			return vec3(fract(_st), ${GLSL_ARG.st}.z);
		`,
    },
    {
        name: "modulateKaleid",
        type: "combineCoord",
        inputs: { nSides: 4 },
        defines: ["TWO_PI"],
        glsl: `
			vec2 _st = ${GLSL_ARG.st}.xy - 0.5;
			float r = length(_st);
			float a = atan(_st.y, _st.x);

			a = mod(a, TWO_PI / nSides);
			a = abs(a - TWO_PI / nSides / 2.);

			return (${GLSL_ARG.color0}.r+r) * vec3(cos(a), sin(a), ${GLSL_ARG.st}.z);
		`,
    },
    {
        name: "modulateScrollX",
        type: "combineCoord",
        inputs: {
            scrollX: 0.5,
            speed: 0,
        },
        glsl: `   
			vec2 _st = ${GLSL_ARG.st}.xy;	
			_st.x += ${GLSL_ARG.color0}.r*scrollX + time * speed;

			return vec3(fract(_st), ${GLSL_ARG.st}.z);
		`,
    },
    {
        name: "modulateScrollY",
        type: "combineCoord",
        inputs: { scrollY: 0.5, speed: 0 },
        glsl: `   
			vec2 _st = ${GLSL_ARG.st}.uv;
			_st.y += ${GLSL_ARG.color0}.r*scrollY + time * speed;

			return vec3(fract(_st), ${GLSL_ARG.st}.z);
		`,
    },
    {
        name: "modulate",
        type: "combineCoord",
        inputs: {
            amountX: 0.1,
            amountY: 0.1,
            amountZ: 0.1,
        },
        glsl: `   
			//  return fract(st+(${GLSL_ARG.color0}.xy-0.5) * vec3(amountX, amountY, amountZ));
			return ${GLSL_ARG.st} + ${GLSL_ARG.color0}.xyz * vec3(amountX, amountY, amountZ);
		`,
        transform: (source, amountX = 0.1, amountY = amountX, amountZ = amountY) => {
            return [source, amountX, amountY, amountZ];
        },
    },
    {
        name: "modulateScale",
        type: "combineCoord",
        inputs: {
            multiple: 1,
            offset: 1,
        },
        glsl: `   
			vec3 _st = ${GLSL_ARG.st} - .5;
			_st *= 1.0 / (offset + vec3(${GLSL_ARG.color0}.rgb) * multiple);
			return _st + .5;
		`,
    },
    {
        name: "modulatePixelate",
        type: "combineCoord",
        inputs: {
            multiple: 10,
            offset: 3,
        },
        glsl: `
			vec3 _st = vec3(offset + ${GLSL_ARG.color0}.x*multiple, offset + ${GLSL_ARG.color0}.y*multiple, offset + ${GLSL_ARG.color0}.z*multiple);
			return (floor(${GLSL_ARG.st} * _st) + 0.5) / _st;
		`,
    },
    {
        name: "modulateRotate",
        type: "combineCoord",
        inputs: {
            multiple: 1,
            offset: 0,
        },
        require: ["rotate3d"],
        defines: ["FORWARD"],
        glsl: `
			vec3 _st = ${GLSL_ARG.st} - .5;
			float angle = offset + ${GLSL_ARG.color0}.x * multiple * -1.;
			_st = rotate3d(_st, FORWARD, angle);
			return _st + 0.5;
		`,
    },
    {
        name: "modulateHue",
        type: "combineCoord",
        inputs: { amount: 1 },
        glsl: `
			vec4 c = ${GLSL_ARG.color0};
			return ${GLSL_ARG.st} + vec3((vec2(c.g - c.r, c.b - c.g) * amount * 1.0 / resolution), ${GLSL_ARG.st}.z);
		`,
    },
];
