import { mat4 } from "gl-matrix";
export function matrixTransformation(matrixRef, thisRef, step) {
    const self = {
        matrix: matrixRef,
        index: step?.index ?? 0,
        offset: step?.offset ?? 1,
        count: step?.count ?? 1,
        identity: () => {
            mat4.identity(self.matrix);
            return thisRef || self;
        },
        rotateX: (angle) => {
            mat4.rotateX(self.matrix, self.matrix, angle);
            return thisRef || self;
        },
        rotateY: (angle) => {
            mat4.rotateY(self.matrix, self.matrix, angle);
            return thisRef || self;
        },
        rotateZ: (angle) => {
            mat4.rotateZ(self.matrix, self.matrix, angle);
            return thisRef || self;
        },
        translate: (x, y, z) => {
            mat4.translate(self.matrix, self.matrix, [x, y, z]);
            return thisRef || self;
        },
        translateX: (x) => {
            mat4.translate(self.matrix, self.matrix, [x, 0, 0]);
            return thisRef || self;
        },
        translateY: (y) => {
            mat4.translate(self.matrix, self.matrix, [0, 0, y]);
            return thisRef || self;
        },
        translateZ: (z) => {
            mat4.translate(self.matrix, self.matrix, [0, z, 0]);
            return thisRef || self;
        },
        scale: (x, y = x, z = y) => {
            mat4.scale(self.matrix, self.matrix, [x, y, z]);
            return thisRef || self;
        },
    };
    return self;
}
