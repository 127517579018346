export default {
    luminance: `
        float luminance(vec3 rgb) {
            const vec3 W = vec3(0.2125, 0.7154, 0.0721);

            return dot(rgb, W);
        }

        float luminance(vec4 rgba) {
            return luminance(rgba.rgb);
        }
    `,
    rgbToHsv: `
        vec3 rgbToHsv(vec3 c){
            vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
            vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
            vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

            float d = q.x - min(q.w, q.y);
            float e = 1.0e-10;
            return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
        }
    `,
    hsvToRgb: `
        vec3 hsvToRgb(vec3 c){
            vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
            vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
            return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
        }
    `,
    palette: `
        vec3 palette(float t, vec3 a, vec3 b, vec3 c, vec3 d){
            return a + b * cos(6.28318*(c*t+d));
        }
    `,
};
