import Texture from "./texture";
export default class Source extends Texture {
    constructor(synth, index) {
        super(synth, { name: "s" + index });
        this.dynamic = false;
    }
    init(sourceOrProps, dynamic = false) {
        if (typeof sourceOrProps === "object" && "src" in sourceOrProps) {
            const { src, dynamic } = sourceOrProps;
            this.dynamic = dynamic;
            return super.create(src);
        }
        else {
            this.dynamic = dynamic;
            return super.create(sourceOrProps);
        }
    }
    async initImage(src) {
        return new Promise(resolve => {
            const image = new Image();
            image.crossOrigin = "anonymous";
            image.src = src;
            image.addEventListener("load", () => {
                this.dynamic = false;
                this.create(image);
                resolve(this);
            });
        });
    }
    initText(text, font = "14px monospace", color = "#fff") {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = this.synth.width;
        canvas.height = this.synth.height;
        ctx.fillStyle = color;
        ctx.font = font;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(text, canvas.width / 2, canvas.height / 2);
        this.dynamic = false;
        return this.create(canvas);
    }
    async initCam(index, params = {
        // se si setta width,height, si risolve il warning  GL_INVALID_OPERATION, (si vede anche meglio) ma la la cam non si vede nel suo aspect originale (risolto con aspectRatio?)
        height: this.synth.height,
        width: this.synth.width,
        aspectRatio: this.synth.width / this.synth.height,
        frameRate: 60,
    }) {
        return Webcam(index, params)
            .then(cam => this.initVideo(cam))
            .catch(err => console.log("could not get camera", err));
    }
    // index only relevant in atom-hydra + desktop apps
    async initScreen(params = {
        width: this.synth.width,
        height: this.synth.height,
        frameRate: 60,
    }) {
        return Screen(params)
            .then(screen => this.initVideo(screen))
            .catch(err => console.log("could not get screen", err));
    }
    async initVideo(videoOrurl, params = {}) {
        return new Promise(resolve => {
            const video = typeof videoOrurl === "string" ? document.createElement("video") : videoOrurl;
            if (typeof videoOrurl === "string") {
                video.src = videoOrurl;
                video.preload = "auto";
                video.playsInline = true;
            }
            video.crossOrigin = "anonymous";
            video.muted = typeof params.muted === "undefined" ? true : params.muted;
            video.loop = typeof params.loop === "undefined" ? true : params.loop;
            this.dynamic = true;
            video.addEventListener("canplay", async () => {
                this.width = video.videoWidth;
                this.height = video.videoHeight;
                if (typeof params.autoPlay === "undefined" || params.autoPlay)
                    await video.play();
                resolve(this.create(video));
            });
            // // check video loadeddata
            // if (video.readyState >= video.HAVE_FUTURE_DATA) {
            // 	console.log("video.readyState >= video.HAVE_FUTURE_DATA")
            // 	resolve(this.create(video, true))
            // 	if (typeof params.autoPlay === "undefined" || params.autoPlay) {
            // 		video.play()
            // 	}
            // } else {
            // 	console.log("video.readyState < video.HAVE_FUTURE_DATA")
            // 	if (typeof params.autoPlay === "undefined" || params.autoPlay) {
            // 		console.log("video.play()")
            // 		video.addEventListener("canplaythrough", () => {
            // 			resolve(this.create(this.src, true))
            // 			video.play()
            // 		})
            // 	} else {
            // 		resolve(this.create(this.src, true))
            // 	}
            // }
        });
    }
    update() {
        if (this.source && this.dynamic) {
            if (this.source instanceof HTMLVideoElement && this.source.readyState === this.source.HAVE_ENOUGH_DATA) {
                const video = this.source;
                const pb = Math.max(0.1, Math.min(16, this.synth.speed));
                if (pb != video.playbackRate) {
                    video.playbackRate = pb;
                }
                // const videoTime = this.synth.time * this.synth.speed
                //video.currentTime = video.loop ? videoTime % video.duration : videoTime
            }
            super.update();
        }
        return this;
    }
    resize(width, height) {
        // if (this.source instanceof HTMLVideoElement) {
        // 	this.source.width = width
        // 	this.source.height = height
        // 	this.width = this.source.videoWidth
        // 	this.height = this.source.videoHeight
        // }
        // super.resize(width, height)
        return this;
    }
    clear() {
        if (this.source && this.source instanceof HTMLVideoElement) {
            this.source.pause();
            if (this.source.srcObject) {
                const mediaStream = this.source.srcObject;
                if (mediaStream instanceof MediaStream)
                    mediaStream.getTracks().forEach(track => track.stop());
            }
        }
        super.clear();
    }
}
////////////////////////////////////
function Webcam(deviceId, videoParams = {}) {
    return new Promise((resolve, reject) => {
        navigator.mediaDevices
            .enumerateDevices()
            .then(devices => devices.filter(devices => devices.kind === "videoinput"))
            .then(cameras => {
            const constraints = { audio: false, video: videoParams };
            if (cameras[deviceId]) {
                constraints["video"]["deviceId"] = { exact: cameras[deviceId].deviceId };
            }
            return window.navigator.mediaDevices.getUserMedia(constraints);
        })
            .then(stream => {
            const video = document.createElement("video");
            video.srcObject = stream;
            resolve(video);
        })
            .catch(console.log.bind(console));
    });
}
function Screen(videoParams) {
    return new Promise(function (resolve, reject) {
        navigator.mediaDevices
            .getDisplayMedia({ video: videoParams })
            .then(stream => {
            const video = document.createElement("video");
            video.srcObject = stream;
            resolve(video);
        })
            .catch(err => reject(err));
    });
}
