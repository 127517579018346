import earcut from "earcut";
import { annulus, box, capsule, circle, cone, cube, cylinder, disc, ellipse, ellipsoid, icosahedron, icosphere, plane, quad, reuleux, roundedCube, roundedRectangle, sphere, squircle, stadium, superellipse, tetrahedron, torus, utils, } from "primitive-geometry";
import Buffer, { GL_ELEMENT_ARRAY_BUFFER } from "../shader/buffer";
import Shader, { GL_TRIANGLES } from "../shader/shader";
import VAO from "../shader/vao";
import { nameOrProps } from "../utils";
import Model from "./model";
const Primitives = {
    box: (synth, props) => createModel(synth, "box", box, props),
    circle: (synth, props) => createModel(synth, "circle", circle, props),
    quad: (synth, props) => createModel(synth, "quad", quad, props),
    plane: (synth, props) => createModel(synth, "plane", plane, props),
    roundedRectangle: (synth, props) => createModel(synth, "roundedRectangle", roundedRectangle, props),
    stadium: (synth, props) => createModel(synth, "stadium", stadium, props),
    ellipse: (synth, props) => createModel(synth, "ellipse", ellipse, props),
    disc: (synth, props) => createModel(synth, "disc", disc, props),
    superellipse: (synth, props) => createModel(synth, "superellipse", superellipse, props),
    squircle: (synth, props) => createModel(synth, "squircle", squircle, props),
    annulus: (synth, props) => createModel(synth, "annulus", annulus, props),
    reuleux: (synth, props) => createModel(synth, "reuleux", reuleux, props),
    cube: (synth, props) => createModel(synth, "cube", cube, props),
    roundedCube: (synth, props) => createModel(synth, "roundedCube", roundedCube, props),
    sphere: (synth, props) => createModel(synth, "sphere", sphere, props),
    icosphere: (synth, props) => createModel(synth, "icosphere", icosphere, props),
    ellipsoid: (synth, props) => createModel(synth, "ellipsoid", ellipsoid, props),
    cylinder: (synth, props) => createModel(synth, "cylinder", cylinder, props),
    cone: (synth, props) => createModel(synth, "cone", cone, props),
    capsule: (synth, props) => createModel(synth, "capsule", capsule, props),
    torus: (synth, props) => createModel(synth, "torus", torus, props),
    tetrahedron: (synth, props) => createModel(synth, "tetrahedron", tetrahedron, props),
    icosahedron: (synth, props) => createModel(synth, "icosahedron", icosahedron, props),
    //////////////////////////////
    poly: (synth, props) => createPolygon(synth, props),
    polygon: (synth, props) => createPolygon(synth, props),
    triangle: (synth, props) => createPolygon(synth, { ...props, sides: 3 }),
    rect: (synth, props) => createPolygon(synth, { ...props, sides: 4 }),
    line: (synth, props) => createPolygon(synth, { ...props, sides: 2 }),
};
export function triangulate(positions, holes, stride = 3) {
    return Uint8Array.from(earcut(positions, holes, stride));
}
//const VAOS: Record<string, VAO> = {}
function createModel(synth, primitiveName, primitive, props) {
    props = nameOrProps(props);
    const model = primitive(props);
    // if (primitiveName === "box" || primitiveName === "plane") {
    // 	model.cells = computeEdges(model.positions, model.cells, 4)
    // }
    // if (primitiveName === "circle") {
    // 	model.positions = new Float32Array((model.positions.length / 2) * 3).map((_, index) =>
    // 		index % 3 === 2 ? 0 : model.positions[Math.round((index * 2) / 3)]
    // 	)
    // }
    //if (!VAOS[primitiveName]) {
    const attrs = {};
    let cells = null;
    if ("positions" in model)
        attrs.position = new Buffer(synth, {
            data: model.positions,
            size: 3,
        });
    if ("cells" in model)
        cells = new Buffer(synth, {
            data: model.cells,
            size: 3,
            target: GL_ELEMENT_ARRAY_BUFFER,
        });
    if ("normals" in model)
        attrs.normal = new Buffer(synth, {
            data: model.normals,
            size: 3,
        });
    if ("uvs" in model)
        attrs.texCoord = new Buffer(synth, {
            data: model.uvs,
            size: 2,
        });
    const vao = new VAO(synth, {
        attrs,
        cells,
        elements: model.cells.length,
        primitive: GL_TRIANGLES,
    });
    //}
    return new Model(synth, {
        name: props.name ?? primitiveName,
        ...Shader.defaultGydraShader,
        vao,
    });
}
function createPolygon(synth, props) {
    const defaultPolygonProps = { sides: 5, s: 0.5, a: 0, adaptScale: -Math.PI / 2 };
    let { sides, s, sx, sy, a, adaptScale } = { ...defaultPolygonProps, ...props };
    sx = typeof sx === "number" ? sx : s;
    sy = typeof sy === "number" ? sy : s;
    const positions = [];
    const normals = [];
    const texCoords = [];
    switch (sides) {
        case 2:
            positions.push(-0.5, 0, 0, 0.5, 0, 0);
            normals.push(0, 0, 1, 0, 0, 1);
            texCoords.push(0, 0, 1, 0);
            break;
        case 3:
            positions.push(-0.5, -0.5, 0, 0.5, -0.5, 0, 0, 0.5, 0);
            normals.push(0, 0, 1, 0, 0, 1, 0, 0, 1);
            texCoords.push(0, 0, 1, 0, 0.5, 1);
            break;
        case 4:
            positions.push(-0.5, -0.5, 0, 0.5, -0.5, 0, 0.5, 0.5, 0, -0.5, 0.5, 0);
            normals.push(0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1);
            texCoords.push(0, 0, 1, 0, 1, 1, 0, 1);
            break;
        default:
            for (let i = 0; i < sides; i++) {
                const theta = a + (i / sides) * Math.PI * 2;
                const px = Math.cos(theta);
                const py = Math.sin(theta);
                positions.push(px * sx, py * sx, 0);
                texCoords.push(px * 0.5 + 0.5, py * 0.5 + 0.5);
                normals.push(0, 0, 1);
            }
            break;
    }
    const indices = triangulate(positions, null, 3);
    return new Model(synth, {
        name: "polygon",
        // attributes: {
        // 	positions,
        // 	indices,
        // 	normals,
        // 	texCoords,
        // 	elements: indices.length,
        // },
    });
}
export default Primitives;
function computeEdges(positions, cells, stride = 3) {
    // @ts-ignore
    const edges = new (utils.getCellsTypedArray(cells))(cells.length * 2);
    let cellIndex = 0;
    for (let i = 0; i < cells.length; i += stride) {
        for (let j = 0; j < stride; j++) {
            const a = cells[i + j];
            const b = cells[i + ((j + 1) % stride)];
            edges[cellIndex] = Math.min(a, b);
            edges[cellIndex + 1] = Math.max(a, b);
            cellIndex += 2;
        }
    }
    return edges;
}
