export default {
    opUnion: `
        vec4 opUnion(vec4 d1, vec4 d2, float smth) {
            if (smth == 0.0) return d1.w < d2.w ? d1 : d2;

            float h = clamp(0.5 + 0.5 * (d2.w - d1.w) / smth, 0.0, 1.0);
            return mix(d2, d1, h) - smth * h * (1.0 - h);
        }
        vec4 opUnion(vec4 d1, vec4 d2) { return opUnion(d1, d2, 0.0);  }
        float opUnion(float d1, float d2, float smth) { return opUnion(vec4(d1), vec4(d2), smth).w; }
        float opUnion(float d1, float d2) { return opUnion(d1, d2, 0.0); }
    `,
    opSub: `
    vec4 opSub(vec4 d1, vec4 d2, float smth) {
            if (smth == 0.0) return d1.w > -d2.w? d1 : vec4(d2.rgb,-d2.w);

            float h = max(smth-abs(-d1.w-d2.w),0.0);
            return max(-d1, d2) + h*h*0.25/smth;
        }
        vec4 opSub(vec4 d1, vec4 d2) { return opSub(d1, d2, 0.0); }
        float opSub(float d1, float d2, float smth) { return opSub(vec4(d1), vec4(d2), smth).w; }
        float opSub(float d1, float d2) { return opSub(d1, d2, 0.0); }
    `,
    opIntersect: `
        float opIntersect(float d1, float d2, float smth) {
            if (smth == 0.0) return max(d1,d2);

            float h = clamp(0.5 - 0.5 * (d2 - d1) / smth, 0.0, 1.0);
            return mix(d2, d1, h) + smth * h * (1.0 - h);
        }
        float opIntersect(float d1, float d2) { return opIntersect(d1, d2, 0.0); }
    `,
    opXor: `
        float opXor(float d1, float d2) {
            return max(min(d1,d2),-max(d1,d2));
        }
    `,
};
