const KERNELS = {
    NORMAL: [0, 0, 0, 0, 1, 0, 0, 0, 0],
    GAUSSIAN_BLUR: [0.045, 0.122, 0.045, 0.122, 0.332, 0.122, 0.045, 0.122, 0.045],
    GAUSSIAN_BLUR_2: [1, 2, 1, 2, 4, 2, 1, 2, 1],
    GAUSSIAN_BLUR_3: [0, 1, 0, 1, 1, 1, 0, 1, 0],
    UNSHARPEN: [-1, -1, -1, -1, 9, -1, -1, -1, -1],
    SHARPNESS: [0, -1, 0, -1, 5, -1, 0, -1, 0],
    SHARPEN: [-1, -1, -1, -1, 16, -1, -1, -1, -1],
    EDGE_DETECT: [-0.125, -0.125, -0.125, -0.125, 1, -0.125, -0.125, -0.125, -0.125],
    EDGE_DETECT_2: [-1, -1, -1, -1, 8, -1, -1, -1, -1],
    EDGE_DETECT_3: [-5, 0, 0, 0, 0, 0, 0, 0, 5],
    EDGE_DETECT_4: [-1, -1, -1, 0, 0, 0, 1, 1, 1],
    EDGE_DETECT_5: [-1, -1, -1, 2, 2, 2, -1, -1, -1],
    EDGE_DETECT_6: [-5, -5, -5, -5, 39, -5, -5, -5, -5],
    SOBEL_HORIZONTAL: [1, 2, 1, 0, 0, 0, -1, -2, -1],
    SOBEL_VERTICAL: [1, 0, -1, 2, 0, -2, 1, 0, -1],
    PREV_IT_HORIZONTAL: [1, 1, 1, 0, 0, 0, -1, -1, -1],
    PREV_IT_VERTICAL: [1, 0, -1, 1, 0, -1, 1, 0, -1],
    BOX_BLUR: [0.111, 0.111, 0.111, 0.111, 0.111, 0.111, 0.111, 0.111, 0.111],
    TRIANGLE_BLUR: [0.0625, 0.125, 0.0625, 0.125, 0.25, 0.125, 0.0625, 0.125, 0.0625],
    EMBOSS: [-2, -1, 0, -1, 1, 1, 0, 1, 2],
};
export { KERNELS };
export function computeKernelWeight(kernel) {
    const weight = kernel.reduce((prev, curr) => prev + curr);
    return weight <= 0 ? 1 : weight;
}
export default {
    sobel: `
        vec2 _sobel(sampler2D tex, vec2 uv, float spread)
        {
            vec3 offset = vec3(1.0 / resolution, 0.0) * spread;
            vec2 grad = vec2(0.0);
            grad.x -= luminance(texture(tex, uv - offset.xy)) * 1.0;
            grad.x -= luminance(texture(tex, uv - offset.xz)) * 2.0;
            grad.x -= luminance(texture(tex, uv + offset.xy * vec2(-1.0, 1.0))) * 1.0;
            grad.x += luminance(texture(tex, uv + offset.xy * vec2(1.0, -1.0))) * 1.0;
            grad.x += luminance(texture(tex, uv + offset.xz)) * 2.0;
            grad.x += luminance(texture(tex, uv + offset.xy)) * 1.0;
            grad.y -= luminance(texture(tex, uv - offset.xy)) * 1.0;
            grad.y -= luminance(texture(tex, uv - offset.zy)) * 2.0;
            grad.y -= luminance(texture(tex, uv + offset.xy * vec2(1.0, -1.0))) * 1.0;
            grad.y += luminance(texture(tex, uv + offset.xy * vec2(-1.0, 1.0))) * 1.0;
            grad.y += luminance(texture(tex, uv + offset.zy)) * 2.0;
            grad.y += luminance(texture(tex, uv + offset.xy)) * 1.0;
            return grad;
        }	
    `,
    convolution: `
        vec4 _convolution(sampler2D tex, vec2 st, float kernel[9], vec2 spread) {
            float kernelWeight = .0;
            for (int i = 0; i < 9; i++)
                kernelWeight += kernel[i];

            kernelWeight = max(kernelWeight, 1.0);

            float a = texture(tex, st).a;

            vec4 colorSum =
                texture(tex, st + spread * vec2(-1, -1)) * kernel[0] +
                texture(tex, st + spread * vec2( 0, -1)) * kernel[1] +
                texture(tex, st + spread * vec2( 1, -1)) * kernel[2] +
                texture(tex, st + spread * vec2(-1,  0)) * kernel[3] +
                texture(tex, st + spread * vec2( 0,  0)) * kernel[4] +
                texture(tex, st + spread * vec2( 1,  0)) * kernel[5] +
                texture(tex, st + spread * vec2(-1,  1)) * kernel[6] +
                texture(tex, st + spread * vec2( 0,  1)) * kernel[7] +
                texture(tex, st + spread * vec2( 1,  1)) * kernel[8] ;
            
            return vec4((colorSum / kernelWeight).rgb, a);
        }
    `,
};
