export default {
    rotate3d: `
    mat2 rotate2d(float angle) {
        float s = sin(angle);
        float c = cos(angle);
        return mat2(c, -s, s, c);
    }

    mat4 rotationMatrix(vec3 axis, float angle) {
        axis = normalize(axis);
        float s = sin(angle);
        float c = cos(angle);
        float oc = 1.0 - c;
        
        return mat4(oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
                    oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
                    oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
                    0.0,                                0.0,                                0.0,                                1.0);
    }
    
    vec3 rotate3d(vec3 v, vec3 axis, float angle) {
        mat4 m = rotationMatrix(axis, angle);
        return (m * vec4(v, 1.0)).xyz;
    }
    `,
    polar: `
    vec2 toPolar(vec2 cartesian){
        float distance = length(cartesian);
        float angle = atan(cartesian.y, cartesian.x);
        return vec2(angle / TWO_PI, distance);
    }
    
    vec2 toCartesian(vec2 polar){
        vec2 cartesian;
        cartesian.x = cos(polar.x * TWO_PI);
        cartesian.y = sin(polar.x * TWO_PI);
        return cartesian * polar.y;
    }
    `,
};
