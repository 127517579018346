import React from "react"
import backgroundVideo from "url:../assets/background.mp4"
import { Gydra } from "../assets/vendor/gydra"

if (!document.head.querySelector(`link[href="${backgroundVideo}"]`)) {
	const linkPreload = document.createElement("link")
	linkPreload.rel = "prefetch"
	linkPreload.as = "video"
	linkPreload.href = backgroundVideo
	document.head.appendChild(linkPreload)
}

interface GydraRendererProps {
	className?: string
	play?: boolean
}

function GydraRenderer(props: GydraRendererProps) {
	const canvasRef = React.useRef<HTMLCanvasElement>(null)

	const video = React.useMemo(() => {
		const video = document.createElement("video")
		video.src = backgroundVideo
		video.crossOrigin = "anonymous"
		video.loop = true
		video.muted = true
		return video
	}, [])

	React.useEffect(() => {
		if (props.play) {
			video.currentTime = 0
		}
	}, [props.play])

	React.useEffect(() => {
		if (!canvasRef.current || !video) return

		const gydra = new Gydra({
			canvas: canvasRef.current,
			makeGlobal: true,
		}) as Gydra

		gydra.export("video", video)

		const source = `
            await s0.initVideo(video, {
                autoPlay: true,
                loop: true,
				muted: true,
            })

			update = () => {

			}

			function srcRel(tex) {
				if (!tex.source) return src(tex);
				const w = () => tex.source.videoWidth / tex.source.videoHeight
				const h = () => tex.source.videoHeight / tex.source.videoWidth
				const cw = () => width / height;
				const ch = () => height / width;
				return src(tex).mask(shape(4, 1, 0)).scale(
					1,
					() => {
						const _cw = cw();
						const _w = w();
						return _cw > _w ? _w / _cw : 1;
					},
					() => {
						const _ch = ch();
						const _h = h();
						return _ch > _h ? _h / _ch : 1;
					}
				);
			};

            srcRel(s0).scale(() => width > height ? .7 : 2).out(o0)

			src(o3).add(shape(200, .0, .2).scale(1, 1 / ratio).translate(() => mouseX, () => mouseY), .4)
			.blend(solid(0), .005).out(o3)

			src(o0).mask(o3).sobel(2, .8, 3, 3).colorama().modulateScale(o3).out(o2)

			src(o1)
				.add(solid(0,0,0))
				.blend(src(o0), 1)
				.layer(src(o2), .5)
				.out(o1)

			render(o1)
        `

		gydra.eval(source)
	}, [canvasRef, video])

	return <canvas className={props.className} ref={canvasRef} />
}

export default GydraRenderer
