import { setFunction } from "../../glsl";
import Material from "../material";
const flat = {
    name: "_flat",
    type: "src",
    glsl: `
		vec3 pos = vPosition;
		vec3 fdx = vec3(dFdx(pos.x), dFdx(pos.y), dFdx(pos.z));
		vec3 fdy = vec3(dFdy(pos.x), dFdy(pos.y), dFdy(pos.z));
		vec3 normal = normalize(cross(fdx, fdy));
		return vec4(normal * 0.5 + 0.5, 1.0);
	`,
};
setFunction(flat);
export default class Flat extends Material {
    constructor(synth) {
        super(synth, flat);
    }
}
