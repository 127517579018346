import React from "react"
import { createRoot } from "react-dom/client"

import GydraRenderer from "./GydraRenderer"
import Anim from "./components/Anim"
import AnimText from "./components/AnimText"
import Blink from "./components/Blink"

import { logEvent } from "firebase/analytics"
import "../style/index.scss"
import { analytics } from "./firebase"

function App() {
	const [init, setInit] = React.useState(false)
	const urls = [
		//"https://www.ildenaro.it/tre-giovani-ad-arteverona-con-il-progetto-the-eye/",
		//"https://www.ilsole24ore.com/art/artverona-inaugura-stagione-fieristica-italia-AELDNY9B",
		//"https://www.artribune.com/arti-visive/arte-contemporanea/2022/10/artverona-10-gallerie-best/7/",
		//"https://www.juliet-artmagazine.com/il-nuovo-che-avanza-prime-impressioni-su-artverona-2022/",
	]

	React.useEffect(() => {
		logEvent(analytics, "page_view", {
			page_title: "Home",
			page_path: "/",
		})
	}, [])

	React.useEffect(() => {
		if (init) {
			logEvent(analytics, "select_content", {
				content_type: "button",
				item_id: "start",
			})

			setTimeout(() => {
				logEvent(analytics, "select_content", {
					content_type: "redirect",
					item_id: "instagram",
				})

				window.location.href = "https://www.instagram.com/neurocinema_/"
			}, 23000 + 3000)
		}
	}, [init])

	/*
	{urls.map((url, index) => (
					<URLPreview key={url} url={url} />
				))}
	*/
	//return <GydraRenderer className="app__background__renderer" play={true} />
	if (init) {
		return (
			<div className="app">
				<Intro />
				<Anim showAfter={23000} persistentChild>
					{started => (
						<div className="app__background">
							<GydraRenderer className="app__background__renderer" play={started} />
						</div>
					)}
				</Anim>
			</div>
		)
	}

	return (
		<div className="app">
			<Start setInit={setInit} />
		</div>
	)
}

function Start(props: { setInit: (b: boolean) => void }) {
	return (
		<div className="app__start" onClick={() => props.setInit(true)}>
			<Anim hideAfter={2000}>
				<AnimText letterDelay={0.2} style={{ display: "block" }} delay={0} duration={2}>
					Hi
				</AnimText>
			</Anim>
			<Anim showAfter={2000}>
				<div>
					<AnimText letterDelay={0.1} style={{ display: "block" }} delay={1.2} duration={1}>
						Click here
					</AnimText>
				</div>
			</Anim>
		</div>
	)
}
function Intro() {
	return (
		<div className="intro">
			<Anim hideAfter={25000}>
				<Anim hideAfter={15000}>
					<>
						<div className="intro__row">
							<AnimText letterDelay={0.1} style={{ fontWeight: 600 }} delay={0} duration={2}>
								Neurocinema
							</AnimText>
							<AnimText delay={1.5}>is</AnimText>
						</div>
						<div className="intro__row">
							<AnimText delay={2}>- A new way of conceiving Art</AnimText>
						</div>
						<div className="intro__row">
							<AnimText delay={3}>- A new audio-visual artwork shaped by the wiever's body</AnimText>
						</div>
						<div className="intro__row">
							<AnimText delay={6}>- An expression of the biology and emotions of the viewer</AnimText>
						</div>
						<div className="intro__row">
							<AnimText delay={8}>- An unique and unrepeatable work of art</AnimText>
						</div>
					</>
				</Anim>
				<Anim showAfter={16000} hideAfter={22000}>
					<AnimText
						style={{
							fontWeight: 600,
							fontSize: "1.2rem",
							textAlign: "center",
							width: "100%!important",
							display: "block",
						}}
						glitch={{
							timeout: 3000,
							timeoutRandGap: 10000,
						}}
					>
						in Neurocinema the viewer is the creator<Blink>.</Blink>
					</AnimText>
				</Anim>
			</Anim>
		</div>
	)
}

const root = createRoot(document.getElementById("root")!)
root.render(<App />)
