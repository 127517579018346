export const SIZE_TO_TYPE = {
    1: "float",
    2: "vec2",
    3: "vec3",
    4: "vec4",
    9: "mat3",
    16: "mat4",
};
export const GLSL_ARG = {
    tex: "tex",
    st: "st",
    color0: "c0",
    color1: "c1",
};
export const GLSL_TYPE_LOOKUP = {
    src: { returnType: "vec4", args: [`vec3 ${GLSL_ARG.st}`] },
    coord: { returnType: "vec3", args: [`vec3 ${GLSL_ARG.st}`] },
    color: { returnType: "vec4", args: [`vec4 ${GLSL_ARG.color0}`] },
    combine: { returnType: "vec4", args: [`vec4 ${GLSL_ARG.color0}`, `vec4 ${GLSL_ARG.color1}`] },
    combineCoord: { returnType: "vec3", args: [`vec3 ${GLSL_ARG.st}`, `vec4 ${GLSL_ARG.color0}`] },
    value: { returnType: "float", args: [`vec3 ${GLSL_ARG.st}`] },
};
