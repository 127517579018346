export default [
    {
        name: "glsl",
        type: "code",
        codeType: "src",
    },
    {
        name: "glslColor",
        type: "code",
        codeType: "color",
    },
    {
        name: "glslCoord",
        type: "code",
        codeType: "coord",
    },
    {
        name: "glslCombine",
        type: "code",
        codeType: "combine",
    },
    {
        name: "glslCombineCoord",
        type: "code",
        codeType: "combineCoord",
    },
];
