import React from "react"
import { rand } from "../utils"
import LetterGlitch, { LetterGlitchProps } from "./LetterGlitch"

interface AnimTextProps {
	delay?: number // s
	duration?: number // s
	letterDelay?: number // s
	children: React.ReactNode | React.ReactNode[]
	style?: React.CSSProperties

	glitch?: Partial<LetterGlitchProps> & {
		letterGlitchMultiplier?: number
	}
}

function AnimText(props: AnimTextProps) {
	// from children, extract only characters
	const children = Array.isArray(props.children) ? props.children : [props.children]
	const letterDelay = props.letterDelay ?? 0.04

	const timeout = React.useMemo(() => props.glitch?.timeout ?? rand(5000, 15000), [])
	const duration = React.useMemo(() => props.glitch?.duration ?? rand(100, 300), [])
	const letterGlitchMultiplier = props.glitch?.letterGlitchMultiplier ?? 33
	let currentDelay = props.delay ?? 0
	return (
		<span className="anim-text-container" style={props.style}>
			{children.map((child, index) => {
				if (typeof child !== "string")
					return (
						<span
							key={index}
							className="anim-text"
							style={{
								animationDelay: currentDelay + "s",
								animationDuration: (props.duration ?? 1) + "s",
							}}
						>
							{child}
						</span>
					)
				const letters = child.split("")
				currentDelay += letterDelay * letters.length
				return letters.map((letter, index) => (
					<span
						key={index}
						className="anim-text"
						style={{
							animationDelay: (props.delay ?? 0) + letterDelay * index + "s",
							animationDuration: (props.duration ?? 1) + letterDelay * (letters.length - index) + "s",
						}}
					>
						{letter === " " ? (
							letter
						) : (
							<LetterGlitch
								{...{ ...props.glitch, duration: duration + index * letterGlitchMultiplier, timeout: timeout }}
							>
								{letter}
							</LetterGlitch>
						)}
					</span>
				))
			})}
		</span>
	)
}

export default AnimText
