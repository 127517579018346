import * as gltf from "webgl-gltf";
import Buffer from "../shader/buffer";
import Shader, { GL_TRIANGLES } from "../shader/shader";
import { getFilenameFromPath } from "../utils";
import PBR from "./materials/pbr";
import Model from "./model";
export function gltfLoader(synth, path) {
    return new Promise((resolve, reject) => {
        const gl = synth.renderer.gl;
        gltf
            .loadModel(gl, path)
            .then(gltfModel => {
            const name = getFilenameFromPath(path);
            const meshMaterial = typeof gltfModel.meshes[0].material !== "undefined"
                ? gltfModel.materials[gltfModel.meshes[0].material]
                : null;
            let material = null;
            if (meshMaterial)
                material = new PBR(synth, meshMaterial);
            const attributes = {};
            let cells = null;
            if (gltfModel.meshes[0].positions)
                attributes.position = new Buffer(synth, gltfModel.meshes[0].positions);
            if (gltfModel.meshes[0].indices)
                cells = new Buffer(synth, gltfModel.meshes[0].indices);
            if (gltfModel.meshes[0].normals)
                attributes.normal = new Buffer(synth, gltfModel.meshes[0].normals);
            if (gltfModel.meshes[0].texCoord)
                attributes.texCoord = new Buffer(synth, gltfModel.meshes[0].texCoord);
            if (gltfModel.meshes[0].tangents)
                attributes.tangent = new Buffer(synth, gltfModel.meshes[0].tangents);
            Object.keys(attributes).forEach(attrName => {
                attributes[attrName].name = name + "_" + attrName;
            });
            if (cells) {
                cells.name = name + "_indices";
            }
            const model = new Model(synth, {
                name,
                ...Shader.defaultGydraShader,
                fragment: material,
                vao: {
                    attrs: attributes,
                    cells,
                    elements: gltfModel.meshes[0].elementCount || cells._data.length,
                    primitive: GL_TRIANGLES,
                },
            });
            resolve(model);
        })
            .catch(reject);
    });
}
export function fragLoader(synth, path, props) {
    return new Promise((resolve, reject) => {
        fetch(path)
            .then(response => response.text())
            .then((data) => {
            const result = new Shader(synth, {
                ...props,
                fragment: data,
                uniforms: [
                    {
                        name: "time",
                        type: "float",
                        value: () => synth.time,
                    },
                    {
                        name: "resolution",
                        type: "vec2",
                        value: () => [synth.width, synth.height],
                    },
                    {
                        name: "mouse",
                        type: "vec2",
                        value: () => [synth.mouseX, synth.mouseY],
                    },
                ],
            });
            resolve(result);
        })
            .catch(reject);
    });
}
