import React from "react"
import { rand } from "../utils"

export interface LetterGlitchProps {
	children: string // letter
	duration?: number
	timeout?: number
	timeoutRandGap?: number
}
function LetterGlitch(props: LetterGlitchProps) {
	const [letterState, setLetterState] = React.useState(props.children)
	const ref = React.useRef<HTMLSpanElement>(null)
	const timeoutRandGap = React.useMemo(() => props.timeoutRandGap ?? rand(-3000, 3000), [])
	const timeout = React.useMemo(() => props.timeout ?? rand(3000, 10000), [])
	const duration = React.useMemo(() => props.duration ?? rand(100, 500), [])

	React.useEffect(() => {
		if (!ref.current) return

		let startTime = 0
		let rif = 0
		let restId
		const finalLetter: number = props.children.charCodeAt(0)
		const startLetter = rand(33, 122)

		function restart(init: boolean) {
			restId = setTimeout(
				() => {
					startTime = 0
					rif = requestAnimationFrame(animate)
				},
				timeout ? timeout * 1.2 : timeout + timeoutRandGap
			)
		}

		function animate(now: number, withoutDuration = false) {
			if (!startTime) startTime = now

			const time = now - startTime
			const progress = time / duration
			const letter = withoutDuration
				? time / (duration * 0.1) + startLetter
				: Math.floor(progress * (finalLetter - startLetter) + startLetter)

			if (withoutDuration || time < duration) {
				setLetterState(String.fromCharCode(letter))
				rif = requestAnimationFrame(t => animate(t, withoutDuration))
			} else {
				cancelAnimationFrame(rif)
				setLetterState(props.children)
				restart(false)
			}
		}

		restart(true)

		// on mouse over, start glitching
		function onMouseOver() {
			cancelAnimationFrame(rif)
			clearTimeout(restId)
			startTime = 0
			rif = requestAnimationFrame(n => animate(n, true))
		}
		function onMouseOut() {
			setTimeout(() => {
				cancelAnimationFrame(rif)
				setLetterState(props.children)
				restart(false)
			}, duration)
		}

		ref.current?.addEventListener("mouseover", onMouseOver)
		ref.current?.addEventListener("mouseout", onMouseOut)

		return () => {
			ref.current?.removeEventListener("mouseover", onMouseOver)
			ref.current?.removeEventListener("mouseout", onMouseOut)

			cancelAnimationFrame(rif)
			setLetterState(props.children)
		}
	}, [ref])

	return (
		<span ref={ref} className="letter-glitch">
			{letterState}
		</span>
	)
}

export default LetterGlitch
