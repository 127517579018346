import { glsl_functions } from "./glsl";
export function strToLines(str = "") {
    return str
        .split("\n")
        .map((line, index) => {
        const lineIndex = "0".repeat(3 - (index + 1).toString().length) + (index + 1).toString();
        return `${lineIndex}: ${line}`;
    })
        .join("\n");
}
export function minifyCode(source) {
    return source.trim().replaceAll("\t", "").replaceAll("  ", "").replaceAll("\n", "");
}
export function glslFn(name) {
    const fn = glsl_functions.find(f => f.name === name);
    if (!fn)
        throw new Error(`Function ${name} not found`);
    return fn;
}
export function trimSource(source) {
    return source
        .trim()
        .split("\n")
        .map(s => s.replaceAll("\t", "").replaceAll("  ", ""))
        .join("\n\t");
}
export function mod(a, b) {
    return ((a % b) + b) % b;
}
export function map(value, inputMin, inputMax, outputMin, outputMax) {
    return ((value - inputMin) * (outputMax - outputMin)) / (inputMax - inputMin) + outputMin;
}
export function gridSize(count) {
    const sqrt = Math.sqrt(count);
    let closestDivisor = 1;
    for (let i = Math.floor(sqrt); i > 1; i--) {
        if (count % i === 0) {
            closestDivisor = i;
            break;
        }
    }
    return [count / closestDivisor, closestDivisor];
}
export function ucfirst(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
const pathRegexp = /^(\/)?(.+\/)*(.+)\.(.+)$/i;
export function getFilenameFromPath(path) {
    const result = pathRegexp.exec(path);
    if (!result)
        return null;
    return result[3];
}
export function array(count, mm = [-1, 1], round = false) {
    const arr = [];
    mm = Array.isArray(mm) ? mm : [mm];
    const mmlen = mm.length;
    for (let i = 0; i < count; i++) {
        const j = i * 2;
        const min = mm[j % mmlen];
        const max = mm[(j + 1) % mmlen];
        let v = typeof mm === "number" ? mm : Math.random() * (max - min) + min;
        arr.push(round ? Math.round(v) : v);
    }
    return arr;
}
export function texCoords([columns, rows]) {
    var texCoords = [];
    for (var i = 0; i < rows; i++) {
        for (var j = 0; j < columns; j++) {
            var s = (j / (columns - 1)) * 2 - 1;
            var t = (i / (rows - 1)) * 2 - 1;
            texCoords.push(s, t);
        }
    }
    return new Float32Array(texCoords);
}
export function imageToCanvas(image, dstWidth = image.width, dstHeight = image.height, imageOffsetX = 0, imageOffsetY = 0, srcWidth = image.width, srcHeight = image.height) {
    const canvas = document.createElement("canvas");
    canvas.width = dstWidth;
    canvas.height = dstHeight;
    console.log(srcWidth, srcHeight, imageOffsetX, imageOffsetY);
    const ctx = canvas.getContext("2d");
    ctx.drawImage(image, imageOffsetX, imageOffsetY, srcWidth, srcHeight, 0, 0, dstWidth, dstHeight);
    return canvas;
}
export function toSource(obj) {
    function replacer(key, value) {
        if (typeof value === "function") {
            return value.toString();
        }
        return value;
    }
    return JSON.stringify(obj, replacer);
}
export function nameOrProps(props = {}) {
    return typeof props === "string" ? { name: props } : props;
}
