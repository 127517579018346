import Audio from "./audio";
import { glsl_functions } from "./glsl/index";
import Synth from "./synth";
import { toSource } from "./utils";
const defaultSettings = {
    outputs: 4,
    sources: 4,
    sequences: 4,
    textures: 4,
    makeGlobal: true,
    detectAudio: true,
    precision: "highp",
    debug: true,
    autoTick: true,
    autoResize: true,
    useWorker: false, //typeof window !== "undefined" && window.Worker !== undefined,
    workerPath: "gydra.worker.js",
};
class Gydra {
    static { this.functions = glsl_functions; }
    constructor(settings = {}) {
        this.worker = null;
        this.synth = null;
        settings = { ...defaultSettings, ...settings };
        if (settings.makeGlobal) {
            if (!settings.width && !settings.height) {
                //settings.width = window.innerWidth * window.devicePixelRatio
                //settings.height = window.innerHeight * window.devicePixelRatio
                settings.width = typeof window !== "undefined" ? window.innerWidth : 400;
                settings.height = typeof window !== "undefined" ? window.innerHeight : 400;
            }
        }
        else if (!settings.width || !settings.height) {
            settings.width = settings.canvas?.width || 400;
            settings.height = settings.canvas?.height || 400;
        }
        if (settings.useWorker) {
            try {
                this.initWithWorker(settings);
            }
            catch (e) {
                console.log("Failed to create worker", e);
                this.initWithoutWorker(settings);
            }
        }
        else {
            this.initWithoutWorker(settings);
        }
        this.proxy = new Proxy(this, this.proxyHandler());
        return this.proxy;
    }
    getCanvasAndSize(settings) {
        let width = settings.width, height = settings.height, canvas = settings.canvas;
        if (!width && !height) {
            //width = window.innerWidth * window.devicePixelRatio
            //height = window.innerHeight * window.devicePixelRatio
            width = typeof window !== "undefined" ? window.innerWidth : 400;
            height = typeof window !== "undefined" ? window.innerHeight : 400;
        }
        if (!canvas) {
            canvas = document.createElement("canvas");
            canvas.style.width = "100%";
            canvas.style.height = "100%";
            document.body.appendChild(canvas);
        }
        return { canvas, width, height };
    }
    initWithWorker(settings) {
        const { canvas, width, height } = this.getCanvasAndSize(settings);
        this.worker = new Worker(settings.workerPath);
        const offscreen = canvas.transferControlToOffscreen();
        if (settings.makeGlobal) {
            this.onMouse(canvas);
            if (settings.autoResize)
                this.onResize();
        }
        this.worker.postMessage({ event: "init", settings: { ...settings, canvas: offscreen, width, height } }, [offscreen]);
        console.log("Gydra initialized with worker");
    }
    initWithoutWorker(settings) {
        const { canvas, width, height } = this.getCanvasAndSize(settings);
        this.synth = new Synth({ ...settings, canvas, width, height });
        if (settings.detectAudio) {
            this.synth.audio = new Audio(this.synth);
        }
        if (settings.makeGlobal) {
            this.onMouse(canvas);
            if (settings.autoResize)
                this.onResize();
        }
        console.log("Gydra initialized in local");
    }
    onMouse(element) {
        const rect = element.getBoundingClientRect();
        const mouseMove = (e) => {
            this.proxy.mouseX = (((e.clientX - rect.left) / rect.width) * 2 - 1) * -0.5;
            this.proxy.mouseY = (((e.clientY - rect.top) / rect.height) * 2 - 1) * 0.5;
        };
        element.addEventListener("mousemove", mouseMove, { passive: true });
    }
    onResize() {
        let resizeTimeout;
        window.addEventListener("resize", () => {
            if (resizeTimeout)
                clearTimeout(resizeTimeout);
            resizeTimeout = setTimeout(() => {
                const width = window.innerWidth * devicePixelRatio;
                const height = window.innerHeight * devicePixelRatio;
                this.proxy.resize(width, height);
            }, 100);
        }, { passive: true });
    }
    proxyHandler() {
        if (this.worker) {
            return {
                get: (target, prop, receiver) => (...args) => {
                    let _args = args;
                    if (prop === "export") {
                        const arg = args[1];
                        _args[1] = toSource(arg);
                    }
                    this.worker.postMessage({ event: "call", method: prop, args: _args });
                },
                set: (target, prop, value, receiver) => {
                    this.worker.postMessage({ event: "set", property: prop, args: value });
                    return true;
                },
            };
        }
        return {
            get: (target, prop, receiver) => this.synth[prop],
            set: (target, prop, value, receiver) => {
                this.synth[prop] = value;
                return true;
            },
        };
    }
}
export default Gydra;
