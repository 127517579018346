import { GLSL_ARG } from "../typeMapping";
export default [
    {
        name: "src",
        type: "src",
        inputs: [
            {
                type: "sampler2D",
                name: GLSL_ARG.tex,
                default: NaN,
            },
        ],
        glsl: `return texture(${GLSL_ARG.tex}, fract(${GLSL_ARG.st}.xy));`,
    },
    {
        name: "noise",
        type: "src",
        inputs: {
            scale: 10,
            offset: 0.1,
        },
        glsl: `return vec4(vec3(noise3d(vec3(${GLSL_ARG.st}.xy * scale, offset * time))), 1.0);`,
        require: ["noise3d"],
    },
    {
        name: "osc",
        type: "src",
        inputs: {
            frequency: 60,
            sync: 0.1,
            offset: 0.0,
        },
        glsl: `
			vec2 _st = ${GLSL_ARG.st}.xy;
			float r = sin((_st.x-offset/frequency+time*sync)*frequency)*0.5  + 0.5;
			float g = sin((_st.x+time*sync)*frequency)*0.5 + 0.5;
			float b = sin((_st.x+offset/frequency+time*sync)*frequency)*0.5  + 0.5;

            return vec4(r, g, b, 1.0);
        `,
    },
    {
        name: "shape",
        type: "src",
        inputs: {
            sides: 3,
            radius: 0.3,
            smoothing: 0.005,
        },
        glsl: `
			vec3 _st = ${GLSL_ARG.st} * 2. - 1.;
			// Angle and radius from the current pixel
			float a = atan(_st.x, _st.y) + PI;
			float r = TWO_PI / sides;
			float d = cos(floor(.5 + a/r) * r-a) * length(_st.xy);
			float color = 1.0 - smoothstep(radius, radius + smoothing + 0.000000001, d);
			
			return vec4(vec3(color), 1.0);
		`,
        defines: ["TWO_PI", "PI"],
    },
    {
        name: "gradient",
        type: "src",
        inputs: {
            speed: 0,
        },
        glsl: `return vec4(${GLSL_ARG.st}.xy, sin(time * speed), 1.0);`,
    },
    {
        name: "voronoi",
        type: "src",
        inputs: {
            scale: 5,
            speed: 0.3,
            blending: 0.3,
        },
        glsl: `   
			vec2 _st = ${GLSL_ARG.st}.xy;
			
			vec3 color = vec3(.0);
		 	// Scale
		 	_st *= scale;
		 	
			// Tile the space
			vec2 i_st = floor(_st);
			vec2 f_st = fract(_st);

			float m_dist = 10.;  // minimun distance
			vec2 m_point;        // minimum point

			for (int j=-1; j<=1; j++ ) {
				for (int i=-1; i<=1; i++ ) {
					vec2 neighbor = vec2(float(i), float(j));
					vec2 p = i_st + neighbor;
					vec2 point = fract(sin(vec2(dot(p,vec2(127.1,311.7)),dot(p,vec2(269.5,183.3))))*43758.5453);
					point = 0.5 + 0.5  * sin(time*speed + 6.2831*point);
					vec2 diff = neighbor + point - f_st;
					float dist = length(diff);
					if( dist < m_dist ) {
						m_dist = dist;
						m_point = point;
					}
				}
			}
			
			// Assign a color using the closest point position
			color += dot(m_point,vec2(.3,.6));
			color *= 1.0 - blending*m_dist;
			
			return vec4(color, 1.0);
		`,
    },
    {
        name: "solid",
        type: "src",
        inputs: {
            r: 0,
            g: 0,
            b: 0,
            a: 1,
        },
        glsl: `return vec4(r, g, b, a);`,
    },
    {
        name: "prev",
        type: "src",
        inputs: {},
        glsl: `return texture(prevFrame, fract(${GLSL_ARG.st}).xy);`,
    },
];
