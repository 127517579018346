export default {
    map: `
        float map( float v, float in_min, float in_max, float out_min, float out_max ) {
            float norm = 0.0;
            if ( in_min < in_max ) {
                norm = min(in_max,max(in_min,v)) - in_min;
                norm /= (in_max-in_min);
            } else if ( in_min > in_max ) {
                norm = min(in_min,max(in_max,v)) - in_max;
                norm /= (in_min-in_max);
                norm = 1.0-norm;
            }

            float result = norm * (out_max-out_min) + out_min;
            float mmin = out_min;
            float mmax = out_max;
            if ( out_min > out_max ) {
                mmin = out_max;
                mmax = out_min;
                result = (1.-norm) * (out_min-out_max) + out_max;
            }
            return min(mmax,max(mmin,result));
        }
    `,
    rand: `
    float rand(vec2 co) {
        return fract(sin(dot(co, vec2(12.9898, 78.233))) * 43758.5453);
    }

    float rand(float co) {
        return rand(vec2(co, 0.0));
    }

    float rand(float x, float y) {
        return rand(vec2(x, y));
    }
    `,
    tuv: `
    vec2 tuv(float index, vec2 size) {
        float column = mod(index, size.x);
        float row    = floor(index / size.y);
        return vec2(
            column / (size.y - 1.),
            row / (size.y - 1.)
        );
    }
    vec2 tuv(float index, ivec2 size) {
        return tuv(index, vec2(size));
    }
    vec2 vuv(float index, float count){
        float h = floor(sqrt(count));
        float w = floor(count / h);
        
        return tuv(index, vec2(w, h));
    }
    `,
};
