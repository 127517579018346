import Shader from "./shader/shader";
import Texture from "./texture";
/**
 * La differenza tra Output e Texture è che per Output viene chiamato
 * il metodo update() ad ogni tick del synth, per le texture no.
 * Ad un Output può essere associato uno shader oppure generare uno shader custom oppure
 * quello di default. Se il generator è presente significa che è quello di default.
 */
class Output extends Texture {
    constructor(synth, index) {
        super(synth, { name: "o" + index, len: 2 });
        this.synth = synth;
    }
    generate(fragmentGenerator) {
        if (this.attachedSequence)
            this.attachedSequence.stop();
        if (this.attachedShader) {
            this.attachedShader.clear();
            this.attachedShader.define({ ...Shader.defaultGydraShader.defines });
            this.attachedShader.varyngs({ ...Shader.defaultGydraShader.varyings });
            this.attachedShader.uniform({ ...Shader.defaultGydraShader.uniforms });
            this.attachedShader.vao(Shader.defaultGydraShader.vao);
            this.attachedShader.vertex({ ...Shader.defaultGydraShader.vertex });
            this.attachedShader.fragment(fragmentGenerator);
        }
        else {
            this.attachedShader = new Shader(this.synth, {
                name: "shader_output_" + this.name,
                ...Shader.defaultGydraShader,
                fragment: fragmentGenerator,
            });
        }
        this.attachedShader.name = "shader_output_" + this.name;
        this.attachedShader.out(this);
        this.attachedShader.drawCalls = 0;
        this.activate();
    }
    update(uniforms = []) {
        if (!this.needsUpdate)
            return this;
        //if (this.attachedShader) this.attachedShader.update(uniforms) // synth.tick call update on shader
        // not need call super.update (from texture) because the texture is used for store the shader output
        return this;
    }
    clear() {
        // super.clear() not destroy texture
        this.attachedShader?.clear();
    }
    activate(active = true) {
        this.needsUpdate = active;
        if (this.attachedShader) {
            this.attachedShader.activate(active);
        }
    }
    resize(width, height) {
        super.resize(width, height);
        if (this.attachedShader)
            this.attachedShader.resize(width, height);
        return this;
    }
}
export default Output;
///////////////////////////
