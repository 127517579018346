import { mat4 } from "gl-matrix";
import Shader from "../shader/shader";
import { matrixTransformation } from "./matrix";
const identityMatrix = mat4.create();
mat4.identity(identityMatrix);
export default class Model extends Shader {
    constructor(synth, props = {}) {
        props.vertex = { value: null };
        props.fragment = props.fragment /*material*/ ?? { value: () => "vec4(1.)" };
        super(synth, props);
        this.modelMatrix = mat4.clone(identityMatrix);
        this.camera(null);
        Object.assign(Model.prototype, matrixTransformation(this.modelMatrix, this));
        this.defines["HAS_MODEL_MATRIX"] = true;
        this.uniforms.push({
            name: "modelMatrix",
            type: "mat4",
            value: this.modelMatrix,
        });
    }
    ////////////////////
    vertexColor(...args) {
        if (!this.VAO)
            return this;
        const len = this.VAO._elements || this.VAO._count;
        const colors = new Float32Array(len * 4);
        for (let i = 0; i < colors.length; i += 4) {
            colors[i + 0] = args[(i + 0) % args.length];
            colors[i + 1] = args[(i + 1) % args.length];
            colors[i + 2] = args[(i + 2) % args.length];
            colors[i + 3] = args[(i + 3) % args.length];
        }
        this.VAO.attrs({
            vertexColor: {
                data: colors,
                size: 4,
            },
        });
        return this;
    }
    wireframe(w = true) {
        this.VAO._primitive = w ? this.synth.renderer.gl.LINE_LOOP : this.synth.renderer.gl.TRIANGLES;
        return this;
    }
    primitive(primitive = this.synth.renderer.gl.TRIANGLES) {
        this.VAO._primitive = primitive;
        return this;
    }
    point(pointSize = 1) {
        if (pointSize) {
            this.VAO._primitive = this.synth.renderer.gl.POINTS;
            this.uniform({
                pointSize,
            });
            this.defines["HAS_POINTSIZE"] = true;
        }
        else {
            this.VAO._primitive = this.synth.renderer.gl.TRIANGLES;
            this.uniform({
                pointSize: null,
            });
            delete this.defines["HAS_POINTSIZE"];
        }
        return this;
    }
    ////////////////////
    camera(camera = this.synth.camera) {
        if (camera) {
            this.defines["HAS_CAMERA"] = true;
            // l'aggiornamento della matrice di proiezione è pleonastico siccome la matrice non viene copiata
            this.uniform({
                viewMatrix: camera.viewMatrix,
                projectionMatrix: camera.projectionMatrix,
            });
            // Potrei usare shaderUniform, ma dovrei aggiornare Generator da Uniform[] a ShaderUniforms
        }
        else {
            this.uniform({
                viewMatrix: null,
                projectionMatrix: null,
            });
        }
        return this;
    }
    out(texture = this.synth.defaultOutput) {
        return super.out(texture);
    }
    ////////////////////
    clear() {
        this.modelMatrix = mat4.clone(identityMatrix);
        this.camera(null);
        return super.clear();
    }
    instances(count = 0) {
        this.VAO.instances(count);
        return this;
    }
}
