import React from "react"

interface BlinkProps {
	children: React.ReactNode
}

function Blink(props: BlinkProps) {
	return <span className="blink">{props.children}</span>
}

export default Blink
