export default {
    drawLine: `
    float drawLine(vec2 st, vec2 resolution, vec2 p1, vec2 p2, float size)
    {
        float r = 0.;
        float one_px = 1. / resolution.x; //not really one px
        
        // get dist between points
        float d = distance(p1, p2);
        
        // get dist between current pixel and p1
        float dst = distance(p1, st);

        //if point is on line, according to dist, it should match current uv 
        r = 1.-floor(1.-(size*one_px)+distance (mix(p1, p2, clamp(dst/d, 0., 1.)),  st));
            
        return r;
    }`,
    drawCircle: `
    float drawCircle(vec2 st, vec2 point, float radius) {
        return (distance(point, st) <= radius) ? 1. : 0.;
    }`,
};
